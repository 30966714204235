import {
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import * as React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      margin: 0,
      height: "1.2em",
    },
  })
);

const FILTER_PARAM = "tableFilter";

export const TableSearch: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [tableFilter, setTableFilter] = useState<string>(() => {
    return new URLSearchParams(location.search).get(FILTER_PARAM) || "";
  });

  const search = () => {
    setQueryFilter(tableFilter);
  };

  const clearFilter = () => {
    setTableFilter("");
    setQueryFilter("");
  };

  const onFilterChange = (filter: string) => {
    setTableFilter(filter);
  };

  const setQueryFilter = (filter: string) => {
    const params = new URLSearchParams(location.search);
    params.delete("tableFilter");
    params.delete("page");
    if (filter !== "") {
      params.append("tableFilter", filter);
    }
    history.replace({ search: params.toString() });
  };

  return (
    <TextField
      className={classes.input}
      size="medium"
      type="text"
      name="tableFilter"
      margin="dense"
      onChange={(e) => onFilterChange(e.target.value)}
      value={tableFilter}
      InputProps={{
        startAdornment: (
          <IconButton
            color="default"
            aria-label="Išvalyti"
            component="span"
            onClick={clearFilter}
          >
            <ClearIcon />
          </IconButton>
        ),
        endAdornment: (
          <IconButton
            color="default"
            aria-label="Ieškoti"
            component="span"
            onClick={search}
          >
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  );
};
