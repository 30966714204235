import * as Yup from "yup";
import httpClient from "./config/HttpClient";
import { RESET_PASSWORD_URL } from "./routes";

class PasswordResetModel {
  code!: string;
  password!: string;
  confirmPassword!: string;

  static schema = Yup.object().shape({
    code: Yup.string().required(""),
    password: Yup.string()
      .label("Password")
      .required("Slaptažodis yra privalomas")
      .min(6, "Slaptažodis turi būti ilgesnis"),
    confirmPassword: Yup.string()
      .required("Slaptažodžio patvirtinimas yra privalomas")
      .label("Confirm password")
      .test("passwords-match", "Slaptažodžiai turi sutapti", function (value) {
        return this.parent.password === value;
      }),
  });

  static reset = async (model: PasswordResetModel) => {
    return await httpClient
      .post<PasswordResetModel>(RESET_PASSWORD_URL, model)
      .then((resp) => resp.data);
  };
}
export default PasswordResetModel;
