import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import * as React from "react";
import { NavLinkProps } from "react-router-dom";
import qs from "qs";

export interface SidebarItem extends NavLinkProps {
  text: string;
  adminOnly: boolean;
  icon: React.ReactElement;
}

export const SideBarItems: SidebarItem[] = [
  {
    text: "Pagrindinis",
    to: "/",
    adminOnly: false,
    exact: true,
    icon: <HomeIcon />,
  },
  {
    text: "Vadybininkai",
    to: "/managers",
    adminOnly: true,
    icon: <PeopleIcon />,
  },
  {
    text: "Statistika",
    to: "/statistics",
    adminOnly: true,
    icon: <AssessmentIcon />,
  },
  {
    text: "Laiškai",
    to: "/email-statistics",
    adminOnly: true,
    icon: <AllInboxIcon />,
  },
  {
    text: "Klientai",
    to: "/clients?myClients=false",
    adminOnly: false,
    isActive: (props, location) => {
      const isCorrectParam =
        qs.parse(location.search, { ignoreQueryPrefix: true }).myClients ===
        "false";
      return isCorrectParam && location.pathname.startsWith("/client");
    },
    icon: <AccountBoxIcon />,
  },
  {
    text: "Mano Klientai",
    to: "/clients?myClients=true",
    adminOnly: false,
    isActive: (props, location) => {
      const isCorrectParam =
        qs.parse(location.search, { ignoreQueryPrefix: true }).myClients ===
        "true";
      return isCorrectParam && location.pathname.startsWith("/client");
    },
    icon: <AccountBoxIcon />,
  },
  {
    text: "Paštas",
    to: "/emails",
    adminOnly: false,
    icon: <EmailIcon />,
  },
  {
    text: "Sąskaitos",
    to: "/invoices",
    adminOnly: false,
    icon: <CreditCardIcon />,
  },
];
