import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      overflowY: "hidden",
      maxHeight: "500px",
    },
    pdfPage: {
      overflowY: "hidden",
      maxHeight: "500px",
    },
  })
);
interface ModalProps {
  close(): void;
  pdfUrl(): Promise<string>;
  sendAction?: () => void;
}

const InvoiceModalView: React.FC<ModalProps> = ({
  pdfUrl,
  close,
  sendAction,
}) => {
  const classes = useStyles();
  const [invoiceUrl, setInvoiceUrl] = useState<string | null>(null);

  useEffect(() => {
    pdfUrl().then(setInvoiceUrl);
  }, [pdfUrl]);

  return (
    <div>
      <Dialog open={true} maxWidth="lg" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Sąskaita</DialogTitle>
        <DialogContent className={classes.content}>
          {invoiceUrl && (
            <div>
              <object
                title="sąskaita"
                data={invoiceUrl}
                type="application/pdf"
                width="1200"
                height="800"
              >
                <iframe
                  title="sąskaita"
                  src={invoiceUrl}
                  width="1200"
                  height="800"
                >
                  <p>Naršyklė nepalaiko PDF peržiūros!</p>
                </iframe>
              </object>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="default"
            onClick={close}
          >
            Uždaryti
          </Button>
          {sendAction && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                sendAction();
              }}
            >
              Siųsti
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InvoiceModalView;
