import React, { useEffect, useState } from "react";
import { ClientFilterProvider } from "../../hooks/ClientFilterProvider";
import EmailForm from "../../components/email/EmailForm";
import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import EmailResultsTable from "../../components/email/EmailResultsTable";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { ClientTableAccordion } from "../../components/client/ClientTableAccordion";
const EMAIL = "email";
const RESULT = "results";

export const Email: React.FC = () => {
  const [tab, setTab] = useState(EMAIL);
  const location = useLocation();
  const history = useHistory();

  const setTabParam = (value: string) => {
    history.replace({ search: qs.stringify({ tab: value }) });
  };

  useEffect(() => {
    const tabSearchParam = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).tab as string;
    if (tabSearchParam) {
      setTab(tabSearchParam);
    }
  }, [location.search]);

  return (
    <ClientFilterProvider>
      <Grid
        container
        alignItems="stretch"
        justify="center"
        direction="column"
        spacing={2}
      >
        <Grid item xs>
          <Paper>
            <Tabs
              value={tab}
              onChange={(event, value) => setTabParam(value)}
              aria-label="tab"
            >
              <Tab value={EMAIL} label="Naujas laiškas" />
              <Tab value={RESULT} label="Išsiųsti laiškai" />
            </Tabs>
          </Paper>
        </Grid>

        <Grid item xs>
          {tab === EMAIL && (
            <div>
              <ClientTableAccordion />
              <EmailForm />
            </div>
          )}
        </Grid>
        <Grid item xs>
          {tab === RESULT && <EmailResultsTable />}
        </Grid>
      </Grid>
    </ClientFilterProvider>
  );
};
