import {
  createStyles,
  Drawer,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InvoiceFilterForm from "../components/invoice/InvoiceFilterForm";
import InvoiceTable from "../components/invoice/InvoiceTable";
import InvoiceStatisticsTable from "../components/statistics/InvoiceStatisticsTable";
import InvoiceFilter from "../core/api/invoice/InvoiceFilterModel";
import InvoiceModel from "../core/api/invoice/InvoiceModel";
import { MaterialTableProps } from "material-table";

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    fab: {
      position: "fixed",
      top: theme.spacing(12),
      right: theme.spacing(-4),
      transform: "rotate(-90deg)",
    },
    rootContainer: {
      maxWidth: "90vw",
    },
  })
);

export const Invoices: React.FC = () => {
  const [filter, setFilter] = useState<InvoiceFilter>({
    dateFrom: "",
    dateTo: "",
    author: "",
  });
  const [filterOpen, setFilterOpen] = useState(false);
  function openClose() {
    setFilterOpen(!filterOpen);
  }

  const modifyFilter = (newFilter: InvoiceFilter) => {
    setFilter({ ...filter, ...newFilter });
  };

  const classes = useStyles();
  const invoiceTableRef = useRef<MaterialTableProps<any>>(null);
  return (
    <div>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: filterOpen,
        })}
      >
        <Fab
          color="secondary"
          variant="extended"
          onClick={openClose}
          className={classes.fab}
          size="small"
        >
          Filtruoti
        </Fab>
        <Grid container spacing={3} justify="center" direction="column">
          <Grid item xs>
            <InvoiceTable
              tableRef={invoiceTableRef}
              fetchFunction={(page) => InvoiceModel.listAll(page, filter)}
            />
          </Grid>
          <Grid item xs={9}>
            <InvoiceStatisticsTable filter={filter} />
          </Grid>
        </Grid>
      </div>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={filterOpen}
        onClose={openClose}
        variant="persistent"
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={openClose}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Grid item>
            <InvoiceFilterForm
              filter={filter}
              onFilterChange={(filter) => modifyFilter(filter)}
            />
          </Grid>
        </Grid>
      </Drawer>
      <div className={classes.drawerHeader} />
    </div>
  );
};
