import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Fab,
  Drawer,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { useHistory } from "react-router-dom";
import EmailRecipientsTable from "../../components/email/EmailRecipientsTable";
import BatchEmailModel from "../../core/api/email/BatchEmailModel";
import EmailFilterModel from "../../core/api/email/EmailFilterModel";
import EmailFilterForm from "../../components/email/EmailFilterForm";

interface Props {
  match?: any;
}
const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    rootMargin: {
      margin: theme.spacing(3),
    },
    root: {
      width: "100%",
    },
    goBackFab: {
      position: "fixed",
      top: theme.spacing(8),
      right: theme.spacing(0),
    },
    filterFab: {
      position: "fixed",
      top: theme.spacing(18),
      right: theme.spacing(-4),
      transform: "rotate(-90deg)",
    },
    margin: {
      marginTop: theme.spacing(1),
    },
  })
);

const EmailResultView: React.FC<Props> = (props) => {
  const resultId = props.match.params.resultId;
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState<EmailFilterModel>({
    dateFrom: "",
    dateTo: "",
    user: "",
    status: "",
    email: "",
    clientId: "",
    opened: "",
  });
  function openClose() {
    setFilterOpen(!filterOpen);
  }
  const history = useHistory();
  const classes = useStyles();

  const queryFn = useCallback(
    (page) => {
      return BatchEmailModel.emailRecipients(resultId, page, filter);
    },
    [resultId, filter]
  );

  return (
    <div className={classes.rootMargin}>
      <Grid container spacing={3} className={classes.root}>
        <Fab
          color="default"
          variant="round"
          size="medium"
          className={classes.goBackFab}
          onClick={history.goBack}
        >
          <ArrowLeftIcon />
        </Fab>
        <Fab
          color="secondary"
          variant="extended"
          onClick={openClose}
          className={classes.filterFab}
          size="small"
        >
          Filtruoti
        </Fab>
        <Grid item sm={12} className={classes.margin}>
          <EmailRecipientsTable fetchFunction={queryFn} />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={filterOpen}
        onClose={openClose}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item>
            <EmailFilterForm
              filter={filter}
              onFilterChange={(filter) => {
                setFilter(filter);
                openClose();
              }}
            />
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};

export default EmailResultView;
