import httpClient from "../config/HttpClient";
import { CLIENTS_URL } from "../routes";
import ClientFilter from "./ClientFilter";

class ClientBulkUpdateModel {
  clientState!: string | null;
  validFrom!: string;
  validTo!: string;
  responsibleUser!: string | null;

  static async updateList(filter: ClientFilter, model: ClientBulkUpdateModel) {
    if (filter.state === "") {
      filter.state = null;
    }
    if (model.clientState === "") {
      model.clientState = null;
    }

    return httpClient
      .patch<Number>(CLIENTS_URL + "/bulk", {
        filter: {
          ...filter,
        },
        form: model,
      })
      .then((resp) => resp.data);
  }
}

export default ClientBulkUpdateModel;
