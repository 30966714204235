import * as React from "react";
import { SidebarItem } from "./SidebarItems";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

export const SideBarNavItem: React.FC<SidebarItem> = (props) => {
  return (
    <ListItem
      button
      component={NavLink}
      color="secondary"
      activeStyle={{
        background: "red",
        color: "white",
      }}
      exact={props.exact}
      isActive={props.isActive}
      to={props.to}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  );
};
