import React, { useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import ClientFilter from "../../core/api/clients/ClientFilter";
import ClientBulkUpdateModel from "../../core/api/clients/ClientBulkUpdateModel";
import ManagersSelectInput from "../classifier/ManagersSelectInput";
import ClientStateSelectInput from "../classifier/ClientStateSelectInput";
import customAlert from "../layout/ConfirmDialog";
import {
  NotificationsState,
  useNotifications,
} from "../../hooks/NotificationProvider";
import ClientModel from "../../core/api/clients/ClientModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      "& > *": {
        width: "100%",
      },
    },
    submitButton: {
      marginTop: "24px",
    },
    title: { textAlign: "center" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
interface Props {
  filter: ClientFilter;
  close(): void;
  refresh(): void;
}

const ClientsBulkUpdateModalForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const closePopup = props.close;
  const [value] = useState<ClientBulkUpdateModel>({
    clientState: "",
    validFrom: "",
    validTo: "",
    responsibleUser: "",
  });
  const notifications = useNotifications();

  return (
    <Dialog
      open={true}
      onClose={closePopup}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Daugelio klientų modifikavimo forma
      </DialogTitle>
      <Formik
        initialValues={value}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          update(props.filter, values, notifications, () => {
            closePopup();
            props.refresh();
          }).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<ClientBulkUpdateModel>) => {
          const { values, handleBlur, handleChange, isSubmitting } = props;
          return (
            <Form>
              <DialogContent>
                <Grid
                  container
                  justify="space-around"
                  direction="row"
                  spacing={2}
                >
                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className={classes.textField}
                  >
                    <TextField
                      id="validFrom"
                      type="date"
                      name="validFrom"
                      label="Prenumerata galioja nuo"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      value={values.validFrom}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className={classes.textField}
                  >
                    <TextField
                      id="validTo"
                      type="date"
                      name="validTo"
                      label="Prenumerata galioja iki"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      value={values.validTo}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className={classes.textField}
                  >
                    <ClientStateSelectInput
                      name="clientState"
                      labelId="user-responsible-label"
                      id="user-type-select"
                      value={values.clientState}
                      label="Kliento statusas"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></ClientStateSelectInput>
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className={classes.textField}
                  >
                    <ManagersSelectInput
                      name="responsibleUser"
                      labelId="user-responsible-label"
                      id="user-responsible-select"
                      value={values.responsibleUser}
                      label="Atsakingas asmuo"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></ManagersSelectInput>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  type="button"
                  className="outline"
                  onClick={closePopup}
                  disabled={isSubmitting}
                >
                  Atšaukti
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  Išsaugoti
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

async function update(
  filter: ClientFilter,
  model: ClientBulkUpdateModel,
  notifications: NotificationsState,
  onSuccess: () => void
) {
  const count = await ClientModel.count(filter);
  customAlert({
    title: "Atnaujinti klientus?",
    body: `Ar tikrai atnaujinti klientus (${count})?`,
    action: () => {
      ClientBulkUpdateModel.updateList(filter, model)
        .then((number) => {
          notifications.notify({
            level: "success",
            message: `Atnaujintų klientų skaičius: ${number}`,
          });
        })
        .then(() => onSuccess())
        .catch((err) => {
          notifications.notify({
            level: "error",
            message: err.response.data.description,
          });
        });
    },
    actionName: "Atnaujinti",
  });
}

export default ClientsBulkUpdateModalForm;
