import {
  Checkbox,
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ClientModel from "../../core/api/clients/ClientModel";
import ListItemText from "@material-ui/core/ListItemText";
import { Input } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface Props {
  id: string;
  name: string;
  clientId: string;
  values: string[];
  onValueChange(emails: string[]): void;
  touched?: boolean;
  errorMessage?: string | string[];
}

const ClientEmailSelect: React.FC<Props> = ({
  id,
  name,
  clientId,
  values,
  touched,
  errorMessage,
  onValueChange,
}) => {
  const [emails, setEmails] = useState([] as string[]);
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onValueChange(event.target.value as string[]);
  };

  useEffect(() => {
    ClientModel.findOne(clientId).then((client) => {
      setEmails(client.emails);
    });
  }, [clientId]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="multiple-emails-label-id">Gavėjai</InputLabel>
      <Select
        placeholder="Pagrindinis el. paštas"
        fullWidth
        id={id}
        name={name}
        labelId="multiple-emails-label-id"
        multiple
        value={values}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(", ")}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
          variant: "menu",
          getContentAnchorEl: null,
        }}
      >
        {emails.map((email) => (
          <MenuItem key={email} value={email}>
            <Checkbox checked={values.indexOf(email) > -1} />
            <ListItemText primary={email} />
          </MenuItem>
        ))}
      </Select>
      {errorMessage && touched && (
        <FormHelperText error>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default ClientEmailSelect;
