import React, {useState} from "react";
import {
  Button,
  createStyles,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import {Editor} from "@tinymce/tinymce-react";
import {Alert, AlertTitle} from "@material-ui/lab";
import {useClientFilter} from "../../hooks/ClientFilterProvider";
import customAlert from "../layout/ConfirmDialog";
import {Form, Formik, FormikProps} from "formik";
import BatchEmailModel from "../../core/api/email/BatchEmailModel";
import {isEmptyFilter} from "../../core/api/clients/ClientFilter";
import {useNotifications} from "../../hooks/NotificationProvider";
import {useHistory} from "react-router-dom";
import qs from "qs";
import SubjectInput from "./SubjectInput";
import FileSelector from "./FileSelector";
import {TazDatePicker} from "../dates/TazDatePicker";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      margin: {
        margin: theme.spacing(1),
      },
    })
);

const EmailForm: React.FC = (props) => {
  const classes = useStyles();
  const filter = useClientFilter();
  const notifications = useNotifications();
  const history = useHistory();
  const [customValidation, setCustomValidation] = useState<{
    filterError: null | string;
  }>({filterError: null});
  React.useEffect(() => {
    if (!isEmptyFilter(filter.filter)) {
      setCustomValidation({filterError: null});
    }
  }, [filter.filter]);

  async function handleEmail(value: BatchEmailModel) {
    if (isEmptyFilter(filter.filter)) {
      setCustomValidation({
        filterError: "Laiškų siuntimui filtras yra privalomas!",
      });
      return;
    }
    customAlert({
      action: () => {
        BatchEmailModel.sendBatch(value, filter.filter)
        .then(() => {
          notifications.notify({
            title: "Pradėta siųsti",
            message: "Laiškai pradėti siųsti",
            level: "success",
          });
        })
        .then(() => {
          history.replace({search: qs.stringify({tab: "results"})});
        });
      },
      actionName: "Siųsti",
      body: `Ar tikrai siųsti laišką pasirinktiem klientams?`,
      title: "Siųsti laiškus?",
    });
  }

  return (
      <Paper>
        <Formik
            initialValues={BatchEmailModel.defaultValue()}
            enableReinitialize={true}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              handleEmail(values);
            }}
            validationSchema={BatchEmailModel.schema}
        >
          {(props: FormikProps<BatchEmailModel>) => {
            const {
              values,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
                <Form>
                  <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="stretch"
                      spacing={3}
                  >
                    {customValidation.filterError && (
                        <Grid item xs className={classes.margin}>
                          <Alert severity="error">
                            <AlertTitle>Klaida</AlertTitle>
                            {customValidation.filterError}
                          </Alert>
                        </Grid>
                    )}
                    <Grid item xs className={classes.margin}>
                      <SubjectInput
                          id="content.subject"
                          name="content.subject"
                          onChange={handleChange}
                          value={values.content.subject}
                          error={Boolean(errors.content?.subject)}
                          helperText={errors.content?.subject}
                          onBlur={handleBlur}
                          setValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs className={classes.margin}>
                      <Editor
                          tinymceScriptSrc="/tinymce/tinymce.min.js"
                          id="content.htmlText"
                          textareaName="content.htmlText"
                          init={{
                            setup: function (editor) {
                              editor.ui.registry.addToggleButton("noticeEmail", {
                                icon: "notice",
                                tooltip: "Svarbus laiškas",
                                onAction: (api) => {
                                  api.setActive(!api.isActive());
                                  setFieldValue("content.important", api.isActive());
                                  // Add empty string to remove focus from button
                                  editor.insertContent("");
                                },
                              });
                            },
                            menubar: false,
                            height: "400",
                            font_formats:
                                "Arial=arial,helvetica,sans-serif;Times New Roman=times new roman,times",
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            plugins: [
                              'autolink',
                              'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                              'code', 'fullscreen',
                              'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar:
                                "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | noticeEmail | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                          }}
                          onBlur={handleBlur}
                          onEditorChange={(value, editor) => {
                            setFieldValue("content.htmlText", value, true);
                          }}
                      />
                      {errors.content?.htmlText && (
                          <FormHelperText error>
                            {errors.content?.htmlText}
                          </FormHelperText>
                      )}
                      <Grid item xs className={classes.margin}>
                        <FileSelector
                            id="content.attachments"
                            name="content.attachments"
                            value={values.content.attachments}
                            onChange={setFieldValue}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs className={classes.margin}>
                      <FormGroup row>
                        <FormControlLabel
                            control={
                              <Switch
                                  value={values.withInvoice}
                                  checked={values.withInvoice}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="withInvoice"
                                  id="withInvoice"
                              />
                            }
                            label="Siųsti išankstinę sąskaitą"
                        />
                      </FormGroup>
                    </Grid>
                    {values.withInvoice && (
                        <Grid item xs>
                          <Grid container spacing={3}>
                            <Grid item xs={12} className={classes.margin}>
                              <TazDatePicker
                                  id="invoice.date"
                                  name="invoice.date"
                                  label="Data"
                                  onChange={handleChange}
                                  value={values.invoice?.date}
                                  onBlur={handleBlur}
                                  helperText={errors.invoice?.date}
                                  error={Boolean(errors.invoice?.date)}
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.margin}>
                              <TazDatePicker
                                  id="invoice.periodFrom"
                                  name="invoice.periodFrom"
                                  label="Prenumerata nuo"
                                  onChange={handleChange}
                                  value={values.invoice?.periodFrom}
                                  onBlur={handleBlur}
                                  helperText={errors.invoice?.periodFrom}
                                  error={Boolean(errors.invoice?.periodFrom)}
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.margin}>
                              <TazDatePicker
                                  id="invoice.periodTo"
                                  name="invoice.periodTo"
                                  label="Prenumerata iki"
                                  onChange={handleChange}
                                  value={values.invoice?.periodTo}
                                  onBlur={handleBlur}
                                  helperText={errors.invoice?.periodTo}
                                  error={Boolean(errors.invoice?.periodTo)}
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.margin}>
                              <NumberFormat
                                  customInput={TextField}
                                  name="invoice.price"
                                  id="invoice.price"
                                  label="Kaina"
                                  value={values.invoice?.price ?? ""}
                                  fullWidth
                                  allowedDecimalSeparators={[".", ","]}
                                  helperText={errors.invoice?.price}
                                  error={Boolean(errors.invoice?.price)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.margin}>
                              <TextField
                                  name="invoice.jobTitle"
                                  id="invoice.jobTitle"
                                  label="Išrašęs asmuo:"
                                  value={values.invoice?.jobTitle}
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.margin}>
                      <Button
                          type="submit"
                          fullWidth
                          disabled={isSubmitting}
                          variant="contained"
                          color="primary"
                      >
                        Siųsti
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
            );
          }}
        </Formik>
      </Paper>
  );
};
export default EmailForm;
