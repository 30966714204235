import httpClient from "./config/HttpClient";
import { CLASSIFIER_URL } from "./routes";


class ClassifierModel {
  id !: string;
  name!: string;


  static defaultValue : ClassifierModel = {
      id: "",
      name: ""
  }

  static clientStates = async () => {
    return await httpClient
      .get<ClassifierModel[]>(CLASSIFIER_URL + "/client-states")
      .then((resp) => resp.data)
      ;
  };

  static usersClassifier = async () => {
    return httpClient.get<ClassifierModel[]>(CLASSIFIER_URL + "/users")
    .then((resp) => resp.data)
  
}
 
}
export default ClassifierModel;
