import * as Yup from "yup";
import httpClient from "../config/HttpClient";
import EntityId from "../EntityId";

const SUBJECT_URL = "/users/subjects/"

class SubjectModel {
    id?: string;
    subject!: string

    static defaultValue: SubjectModel = {
        subject: "",
    };

    static schema = Yup.object().shape({
        subject: Yup.string().required("Tema yra privaloma!"),
    });

    static updateOrCreate = async (model: SubjectModel) => {
        if (model.id) {
            return await httpClient
                .put<EntityId>(SUBJECT_URL + model.id, model)
                .then((resp) => resp.data);
        } else {
            return await httpClient
                .post<EntityId>(SUBJECT_URL, model)
                .then((resp) => resp.data);
        }
    };


    static subjects = async () => {
        return await httpClient
            .get<SubjectModel[]>(SUBJECT_URL)
            .then((resp) => resp.data);
    };


    static subject = async (id: string) => {
        return await httpClient
            .get<SubjectModel>(SUBJECT_URL + id)
            .then((resp) => resp.data);
    };

    static delete = async (id: string) => {
        return await httpClient
            .delete(SUBJECT_URL + id)
            .then((resp) => resp.data);
    };

}
export default SubjectModel;
