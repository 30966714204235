import * as Yup from "yup";
import { CLIENTS_URL } from "../routes";
import PageResponse from "../PageResponse";
import ClientFilter from "./ClientFilter";
import PageRequest from "../PageRequest";
import EntityId from "../EntityId";
import qs from "qs";
import { format } from "date-fns";
import httpClient from "../config/HttpClient";

class ClientModel {
  id?: string;
  addressee!: string;
  companyTitle!: string;
  companyCode!: string;
  companyVatCode!: string;
  companyAddress!: string;
  companyPhone!: string;
  emails!: string[];
  validFrom!: string;
  validTo!: string;
  clientState!: string;
  mainEmail?: string;
  comment!: string;
  responsibleUser?: string;
  responsibleUserName!: string;

  static defaultValue: ClientModel = {
    addressee: "",
    companyTitle: "",
    companyCode: "",
    companyVatCode: "",
    companyAddress: "",
    companyPhone: "",
    emails: [""],
    validFrom: "",
    validTo: "",
    clientState: "",
    comment: "",
    responsibleUser: "",
    responsibleUserName: "",
  };

  static schema = Yup.object().shape({
    addressee: Yup.string().required("Įmonės adresatas yra privalomas"),
    companyTitle: Yup.string().required("Įmonės pavadinimas yra privalomas"),
    companyCode: Yup.string().required("Įmonės kodas yra privalomas"),
    companyVatCode: Yup.string().required("Įmonės PVM kodas yra privalomas"),
    companyAddress: Yup.string().required("Įmonės adresas yra privalomas"),
    companyPhone: Yup.string().required("Telefono numeris yra privalomas"),
    clientState: Yup.string().required("Statusas yra privalomas"),
    emails: Yup.array<string>().min(
      1,
      "Bent vienas el. pašto adresas yra reikalingas"
    ),
  });

  static save = async (model: ClientModel) => {
    if (model.id) {
      await httpClient
        .put<EntityId>(CLIENTS_URL + "/" + model.id, model)
        .then((resp) => resp.data);
    } else {
      return await httpClient
        .post<EntityId>(CLIENTS_URL, model)
        .then((resp) => resp.data);
    }
  };

  static findOne = async (id: string) => {
    return await httpClient
      .get<ClientModel>(CLIENTS_URL + "/" + id)
      .then((resp) => resp.data);
  };

  static delete = async (id: string) => {
    await httpClient.delete(CLIENTS_URL + "/" + id).then((resp) => resp.data);
  };

  static clientList = async (filter: ClientFilter, page: PageRequest) => {
    return await httpClient
      .get<PageResponse<ClientModel>>(CLIENTS_URL, {
        params: {
          ...filter,
          ...page,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
          });
        },
      })
      .then((resp) => resp.data as PageResponse<ClientModel>);
  };

  static emailClientList = async (filter: ClientFilter, page: PageRequest) => {
    return await httpClient
      .get<PageResponse<ClientModel>>("/emails/clients", {
        params: {
          ...filter,
          ...page,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
          });
        },
      })
      .then((resp) => resp.data as PageResponse<ClientModel>);
  };

  static count = async (filter: ClientFilter) => {
    return await httpClient
      .get<number>(CLIENTS_URL + "/count", {
        params: {
          ...filter,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
          });
        },
      })
      .then((resp) => resp.data as number);
  };
}
export default ClientModel;
