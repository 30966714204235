import React from "react";
interface ErrorBoundaryProps {
  hasError: boolean;
  error: string;
}

export default class ErrorBoundary extends React.Component<
  {},
  ErrorBoundaryProps
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error: error.toString() };
  }

  handleClose() {
    this.setState({ hasError: false, error: "" });
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, error: error.toString() });
  }

  render() {
    if (this.state.hasError) {
      return <div>Klaida</div>;
    }
    return this.props.children;
  }
}
