import { Grid, makeStyles, createStyles, Theme, Fab } from "@material-ui/core";
import React, { useRef } from "react";
import ClientForm from "../../components/client/ClientForm";
import InvoiceTable from "../../components/invoice/InvoiceTable";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { useHistory } from "react-router-dom";
import { ClientControls } from "../../components/client/ClientControls";
import { MaterialTableProps } from "material-table";
import ReminderViewBox from "../../components/reminder/ReminderView";
import InvoiceModel from "../../core/api/invoice/InvoiceModel";

interface Props {
  match?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    fab: {
      position: "fixed",
      top: theme.spacing(8),
      right: theme.spacing(0),
    },
    bottomRow: {
      "max-height": "500px",
    },
    margin: {
      marginTop: theme.spacing(1),
    },
  })
);

const ClientView: React.FC<Props> = (props) => {
  const invoiceTableRef = useRef<MaterialTableProps<any>>(null);

  const clientId = props.match.params.clientId;

  const history = useHistory();
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={3} className={classes.root}>
        <Fab
          color="default"
          variant="round"
          size="medium"
          className={classes.fab}
          onClick={history.goBack}
        >
          <ArrowLeftIcon />
        </Fab>
        <Grid item sm={12} md={9} className={classes.margin}>
          <ClientForm clientId={clientId} />
        </Grid>
        {clientId && (
          <Grid item sm={12} md={3} className={classes.margin}>
            <ReminderViewBox clientId={clientId} />
          </Grid>
        )}
        {clientId && (
          <Grid item sm={12} md={10} className={classes.bottomRow}>
            <InvoiceTable
              tableRef={invoiceTableRef}
              clientId={clientId}
              fetchFunction={(page) => InvoiceModel.listClient(page, clientId)}
            />
          </Grid>
        )}
        {clientId && (
          <Grid item xs={12} md={2} className={classes.bottomRow}>
            <ClientControls clientId={clientId} tableRef={invoiceTableRef} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ClientView;
