import React from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  TextField,
} from "@material-ui/core";
import { ReminderFilter } from "../../core/api/reminder/ReminderModel";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { TazDatePicker } from "../dates/TazDatePicker";
import { format } from "date-fns";
import ClientStateSelectInput from "../classifier/ClientStateSelectInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  filter: ReminderFilter;
}

const ReminderFilterForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const onClear = () => {
    history.replace({
      search: qs.stringify({
        dateFrom: "",
        dateTo: "",
        companyFilter: "",
        tableFilter: "",
      }),
    });
  };

  return (
    <Formik
      initialValues={props.filter}
      enableReinitialize
      onSubmit={(values, actions) => {
        history.replace({
          search: qs.stringify(values, {
            serializeDate: (date: Date) => {
              return format(date, "yyyy-MM-dd");
            },
          }),
        });
        actions.setSubmitting(false);
      }}
    >
      {(props: FormikProps<ReminderFilter>) => {
        const { values, handleChange, isSubmitting } = props;
        return (
          <Form>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              spacing={1}
              className={classes.margin}
            >
              <Grid item xs>
                <TextField
                  className={classes.margin}
                  name="companyFilter"
                  id="companyFilter"
                  value={values.companyFilter}
                  label="Filtras"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TazDatePicker
                  className={classes.margin}
                  id="dateFrom"
                  name="dateFrom"
                  label="Data nuo"
                  value={values.dateFrom}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TazDatePicker
                  className={classes.margin}
                  id="dateTo"
                  name="dateTo"
                  label="Data iki"
                  value={values.dateTo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <ClientStateSelectInput
                  className={classes.margin}
                  name="clientState"
                  fullWidth
                  id="user-type-select"
                  value={values.clientState}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <Button
                  className={classes.margin}
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Filtruoti
                </Button>
                <Button
                  className={classes.margin}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={onClear}
                  fullWidth
                >
                  Išvalyti
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReminderFilterForm;
