import { add, format } from "date-fns";
import * as Yup from "yup";
import httpClient from "../config/HttpClient";
import EntityId from "../EntityId";
import InvoiceEmailModel from "./InvoiceEmailModel";

class ProformaInvoiceModel {
  date!: string;
  price!: number | null;
  periodFrom!: string;
  periodTo!: string;
  jobTitle!: string | null;

  static defaultValue: ProformaInvoiceModel = {
    date: format(new Date(), "yyyy-MM-dd"),
    price: null,
    periodFrom: format(new Date(), "yyyy-MM-dd"),
    periodTo: format(add(new Date(), { years: 1 }), "yyyy-MM-dd"),
    jobTitle: "",
  };

  static withDates = (periodFrom: string, periodTo: string) => {
    return {
      date: format(new Date(), "yyyy-MM-dd"),
      price: null,
      periodFrom: periodFrom,
      periodTo: periodTo,
      jobTitle: "",
    };
  };

  static schema = Yup.object().shape({
    date: Yup.date().required("Data yra privaloma"),
    periodFrom: Yup.date().required("Prenumeratos pradžios data yra privaloma"),
    periodTo: Yup.date().required("Prenumeratos pabaigos data yra privaloma"),
    price: Yup.number()
      .typeError("Kaina turi būti validus skaičius")
      .required("Kaina yra privaloma"),
  });

  static save = async (model: ProformaInvoiceModel, clientId: string) => {
    return await httpClient
      .post<EntityId>(`/clients/${clientId}/proforma-invoices`, model)
      .then((resp) => resp.data);
  };

  static lastProformaSentDate = async (clientId: string) => {
    const resp = await httpClient.get<string>(
      `/clients/${clientId}/proforma-invoices/last-sent`
    );
    return resp.data as string;
  };

  static pdf = async (clientId: string) => {
    return httpClient
      .get(`/clients/${clientId}/proforma-invoices/pdf`, {
        responseType: "blob",
      })
      .then((resp) => URL.createObjectURL(resp.data));
  };

  static send = async (content: InvoiceEmailModel, clientId: string) => {
    const resp = await httpClient.post(
      `/clients/${clientId}/proforma-invoices/send`,
      content
    );
    return resp.data.content as EntityId;
  };

  static pdfPreview = (model: ProformaInvoiceModel, clientId: string) => {
    return httpClient
      .post(`/clients/${clientId}/proforma-invoices/preview-pdf`, model, {
        responseType: "blob",
      })
      .then((resp) => URL.createObjectURL(resp.data));
  };
}
export default ProformaInvoiceModel;
