import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import { MaterialTableProps } from "material-table";
import * as React from "react";
import EntityId from "../../core/api/EntityId";
import InvoiceEmailModel from "../../core/api/invoice/InvoiceEmailModel";
import InvoiceModel from "../../core/api/invoice/InvoiceModel";
import ProformaInvoiceModel from "../../core/api/invoice/ProformaInvoiceModel";
import ReminderModel from "../../core/api/reminder/ReminderModel";
import InvoiceEmailModalForm from "../email/InvoiceEmailModalForm";
import InvoiceModalForm from "../invoice/InvoiceModalForm";
import InvoiceModalView from "../invoice/InvoiceModalView";
import ProformaInvoiceModalForm from "../invoice/ProformaInvoiceModalForm";
import ReminderModalForm from "../reminder/ReminderModalForm";

interface Props {
  clientId: string;
  tableRef: React.RefObject<MaterialTableProps<any>>;
}

interface EmailState {
  title: string;
  pdfUrl(): Promise<string>;
  send(content: InvoiceEmailModel): Promise<EntityId>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0),
      height: "100%",
    },
  })
);

export const ClientControls: React.FC<Props> = ({ clientId, tableRef }) => {
  const classes = useStyles();
  const [showReminder, setShowReminder] = React.useState(false);
  const [showSia, setShowSia] = React.useState(false);
  const [newSia, setNewSia] = React.useState(false);
  const [newInvoice, setNewInvoice] = React.useState(false);
  const [email, setEmail] = React.useState<EmailState | null>(null);
  const [lastSentDate, setLastSentDate] = React.useState<string>();
  const lastProformaDate = React.useCallback(() => {
    return ProformaInvoiceModel.lastProformaSentDate(clientId);
  }, [clientId]);

  React.useEffect(() => {
    lastProformaDate()
      .then((d) => {
        setLastSentDate(d);
      })
      .catch(() => {});
  }, [lastProformaDate, email]);

  const hideReminderModal = () => {
    setShowReminder(false);
  };

  const showReminderModal = () => {
    setShowReminder(true);
  };

  const hideSiaModal = () => {
    setShowSia(false);
  };

  const showSiaModal = () => {
    setShowSia(true);
  };

  const hideEmailModal = () => {
    setEmail(null);
  };

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justify="center"
      direction="column"
      spacing={3}
      component={Paper}
    >
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setNewSia(true)}
        >
          Nauja išankstinė sąskaita
        </Button>
      </Grid>
      {lastSentDate && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={showSiaModal}
          >
            Paskutinė išrašyta SIA <br /> {lastSentDate}
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={showReminderModal}
        >
          Naujas priminimas
        </Button>
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setNewInvoice(true)}
        >
          Nauja PVM sąskaita
        </Button>
      </Grid>

      {showReminder && (
        <ReminderModalForm
          value={ReminderModel.defaultValue}
          save={(values) => ReminderModel.updateOrCreate(clientId, values)}
          close={hideReminderModal}
        />
      )}

      {showSia && (
        <InvoiceModalView
          close={hideSiaModal}
          pdfUrl={() => ProformaInvoiceModel.pdf(clientId)}
          sendAction={() => {
            hideSiaModal();
            setEmail({
              title: `Siųsti išankstinę sąskaitą?`,
              pdfUrl: () => ProformaInvoiceModel.pdf(clientId),
              send: (content) => ProformaInvoiceModel.send(content, clientId),
            });
          }}
        />
      )}

      {email && (
        <InvoiceEmailModalForm
          clientId={clientId}
          title={email.title}
          close={hideEmailModal}
          pdfUrl={email.pdfUrl}
          onSubmit={email.send}
        />
      )}

      {newInvoice && (
        <InvoiceModalForm
          close={() => setNewInvoice(false)}
          clientId={clientId}
          tableRef={tableRef}
          onSuccess={(id) => {
            setEmail({
              title: `Siųsti sąskaitą NR. ${id}?`,
              pdfUrl: () => InvoiceModel.pdf(id),
              send: (content) => InvoiceModel.send(content, id),
            });
          }}
        />
      )}

      {newSia && (
        <ProformaInvoiceModalForm
          onSuccess={() => {
            setEmail({
              title: `Siųsti išankstinę sąskaitą?`,
              pdfUrl: () => ProformaInvoiceModel.pdf(clientId),
              send: (content) => ProformaInvoiceModel.send(content, clientId),
            });
          }}
          close={() => setNewSia(false)}
          clientId={clientId}
        />
      )}
    </Grid>
  );
};
