import React from "react";
import { Form, Formik, FormikProps } from "formik";
import ManagersModel from "../../core/api/manager/ManagersModel";
import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";

interface Props {
  formValues?: ManagersModel;
  onSuccess(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "450px",
      display: "block",
      margin: "0 auto",
    },
    textField: {
      "& > *": {
        width: "100%",
      },
    },
    submitButton: {
      marginTop: "24px",
    },
    title: { textAlign: "center" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const ManagersModalForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const closePopup = props.onSuccess;
  return (
    <Formik
      initialValues={props.formValues ?? ManagersModel.defaultValue}
      onSubmit={(values, actions) => {
        ManagersModel.updateOrCreate(values)
          .then(() => closePopup())
          .catch((error) => {
            const data = error.response.data;

            data.errors.forEach((element: any) => {
              actions.setFieldError(element.field, element.defaultMessage);
            });
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
      validationSchema={ManagersModel.schema}
    >
      {(props: FormikProps<ManagersModel>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;
        return (
          <Form>
            <DialogContent>
              <Grid
                container
                justify="space-around"
                direction="row"
                spacing={2}
              >
                <Grid
                  item
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  className={classes.textField}
                >
                  <TextField
                    error={errors.username && touched.username ? true : false}
                    id="username"
                    type="text"
                    name="username"
                    label="Vartotojo vardas"
                    disabled={values.id !== undefined}
                    onChange={handleChange}
                    helperText={
                      touched.username && errors.username ? errors.username : ""
                    }
                    fullWidth
                    value={values.username}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  item
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  className={classes.textField}
                >
                  <TextField
                    name="email"
                    id="email"
                    label="El pašto adresas"
                    value={values.email}
                    type="text"
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    error={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  item
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  className={classes.textField}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel id="user-type-label">
                      Vartotojo tipas
                    </InputLabel>
                    <Select
                      name="userType"
                      labelId="user-type-label"
                      id="user-type-select"
                      value={values.userType}
                      label="Vartotojo tipas"
                      error={errors.userType && touched.userType ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={"ADMIN"}>Administratorius</MenuItem>
                      <MenuItem value={"USER"}>Vadybininkas</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  className={classes.textField}
                >
                  <TextField
                    name="firstName"
                    id="firstName"
                    label="Vardas"
                    value={values.firstName}
                    type="text"
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }
                    error={errors.firstName && touched.firstName ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  item
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  className={classes.textField}
                >
                  <TextField
                    name="lastName"
                    id="lastName"
                    label="Pavardė"
                    value={values.lastName}
                    type="text"
                    helperText={
                      errors.lastName && touched.lastName ? errors.lastName : ""
                    }
                    error={errors.lastName && touched.lastName ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  item
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  className={classes.textField}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.active}
                        onChange={handleChange}
                        name="active"
                      />
                    }
                    label="Aktyvus"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                type="button"
                className="outline"
                onClick={closePopup}
                disabled={isSubmitting}
              >
                Atšaukti
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                Išsaugoti
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ManagersModalForm;
