import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useContext, FC } from "react";
import ClientFilter, { defaultFilter } from "../core/api/clients/ClientFilter";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  createStyles,
  Drawer,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import qs from "qs";
import ClientFilterForm from "../components/client/ClientFilterForm";
export interface FilterState {
  filter: ClientFilter;
}

const ClientFilterContext = React.createContext<FilterState>({
  filter: {
    tableFilter: "",
    state: "",
    filter: "",
    idTo: "",
    idFrom: "",
    validFrom: "",
    validTo: "",
    ids: [],
    myClients: false,
  },
});
const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
      height: "100%",
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    fab: {
      position: "fixed",
      top: theme.spacing(12),
      right: theme.spacing(-4),
      transform: "rotate(-90deg)",
    },
  })
);
export const parseFilter = (search: string): ClientFilter => {
  const loaded = qs.parse(search, { ignoreQueryPrefix: true });
  return {
    tableFilter: (loaded.tableFilter as string) || "",
    filter: (loaded.filter as string) || "",
    state: (loaded.state as string) || "",
    validFrom: (loaded.validFrom as string) || "",
    validTo: (loaded.validTo as string) || "",
    ids: (loaded.ids as string[]) || null,
    idTo: (loaded.idTo as string) || "",
    idFrom: (loaded.idFrom as string) || "",
    myClients: loaded.myClients === "true",
  };
};

export const ClientFilterProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [clientFilter, setClientFilter] = useState<ClientFilter>(() => {
    const loaded = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (loaded) {
      return parseFilter(location.search);
    }
    return defaultFilter;
  });

  function openClose() {
    setOpen(!open);
  }

  useEffect(() => {
    setClientFilter(parseFilter(location.search));
  }, [location.search]);

  const classes = useStyles();
  return (
    <ClientFilterContext.Provider
      value={{
        filter: clientFilter,
      }}
    >
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Fab
          color="secondary"
          variant="extended"
          onClick={openClose}
          className={classes.fab}
          size="small"
        >
          Filtruoti
        </Fab>
        {children}
      </div>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={open}
        onClose={openClose}
        variant="persistent"
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={openClose}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Grid item>
            <ClientFilterForm filter={clientFilter} />
          </Grid>
        </Grid>
      </Drawer>
    </ClientFilterContext.Provider>
  );
};

export function useClientFilter() {
  return useContext(ClientFilterContext);
}
