import { format, parseISO } from "date-fns";
import MaterialTable, { MaterialTableProps } from "material-table";
import React, { useEffect, useRef } from "react";
import { EmailRecipient } from "../../core/api/email/BatchEmailModel";
import PageRequest from "../../core/api/PageRequest";
import PageResponse from "../../core/api/PageResponse";
import { icons } from "../TableIcons";
import Check from "@material-ui/icons/Check";
interface Props {
  fetchFunction: (page: PageRequest) => Promise<PageResponse<EmailRecipient>>;
}

const EmailRecipientsTable: React.FC<Props> = ({ fetchFunction }) => {
  const tableRef = useRef<MaterialTableProps<any>>(null);

  useEffect(() => {
    const ref = tableRef.current as any;
    ref.onQueryChange();
  }, [fetchFunction]);

  return (
    <MaterialTable
      icons={icons}
      tableRef={tableRef}
      columns={[
        { title: "Klientas ", field: "clientId" },
        { title: "Gavėjas", field: "email" },
        {
          title: "Data",
          field: "createdAt",
          render: (rowData) => {
            return (
              <span>
                {format(
                  parseISO(String(rowData.createdAt)),
                  "yyyy-MM-dd HH:mm:ss"
                )}
              </span>
            );
          },
        },
        {
          title: "Statusas",
          field: "status",
          lookup: { SENT: "Išsiųsta", ERROR: "Klaida" },
        },
        {
          title: "Klaida",
          field: "errorMessage",
          filtering: false,
          emptyValue: "Nėra",
        },
        {
          title: "Atidarytas",
          field: "opened",
          lookup: { true: <Check />, false: "" },
          headerStyle: {
            textAlign: "center",
          },
          cellStyle: {
            textAlign: "center",
          },
        },
      ]}
      options={{
        sorting: false,
        search: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 20],
        paginationType: "stepped",
        headerStyle: {
          backgroundColor: "#bdbdbd",
        },
        rowStyle: (rowData, index) => {
          if (index % 2) {
            return {};
          }
          return { backgroundColor: "#efefef" };
        },
      }}
      localization={{
        header: {
          actions: "",
        },
      }}
      data={async (query) => {
        const result = await fetchFunction({
          page: query.page,
          size: query.pageSize,
        });
        return {
          data: result.content,
          page: result.number,
          totalCount: result.totalElements,
        };
      }}
      title="Išsiųsti laiškai"
    />
  );
};

export default EmailRecipientsTable;
