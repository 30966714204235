import * as React from "react";
import { useContext, useState, FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  addAuthInterceptor,
  login,
  refreshToken,
  logout,
  addRefreshInterceptor,
} from "../core/api/config/HttpClient";
export interface AuthDetails {
  isLoggedIn: boolean;
  isLoading: boolean;
  token?: String;
  isAdmin: boolean;
  logIn: (form: LoginForm) => Promise<void>;
  logout: () => Promise<void>;
}

const initialState: AuthDetails = {
  isLoggedIn: false,
  isLoading: true,
  isAdmin: false,
  logIn: (form: LoginForm) => Promise.reject(),
  logout: () => Promise.reject(),
};

export interface LoginForm {
  username: string;
  password: string;
  grant_type: string;
}

export interface UserDetails {
  access_token: string;
}

const AuthContext = React.createContext<AuthDetails>(initialState);

export const AuthProvider: FC = ({ children }) => {
  const [auth, setAuth] = useState<UserDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    refreshToken()
      .then((data) => {
        setAuth(data);
        addAuthInterceptor(data.access_token);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setAuth(null);
        setLoading(false);
      });
  }, []);

  // const refreshAuthLogic = (failedRequest: any) => {
  //   return refreshToken()
  //     .then((data) => {
  //       addAuthInterceptor(data.access_token);
  //       failedRequest.response.config.headers["Authorization"] =
  //         "Bearer " + data.access_token;
  //       return Promise.resolve();
  //     })
  //     .catch(() => {
  //       removeAuth();
  //     });
  // };

  const logIn = async (form: LoginForm) => {
    setLoading(true);
    try {
      const resp = await login(form);
      setAuth(resp);
      addAuthInterceptor(resp.access_token);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
    //Todo move me to LoginPage
    history.push("/");
  };

  const removeAuth = async () => {
    await logout();
    setAuth(null);
  };

  addRefreshInterceptor(removeAuth);

  const isAdmin = () => {
    if (auth?.access_token) {
      const authorities = jwt_decode<any>(auth.access_token)
        .authorities as Array<string>;
      return authorities.includes("ADMIN");
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        logIn,
        logout: removeAuth,
        isAdmin: isAdmin(),
        token: auth?.access_token,
        isLoading: loading,
        isLoggedIn: !!auth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
