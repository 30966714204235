import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { TazDatePicker } from "../dates/TazDatePicker";
import ProformaInvoiceModel from "../../core/api/invoice/ProformaInvoiceModel";
import InvoiceModalView from "./InvoiceModalView";
import ClientModel from "../../core/api/clients/ClientModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      "& > *": {
        width: "100%",
      },
    },
    submitButton: {
      marginTop: "24px",
    },
    title: { textAlign: "center" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface Props {
  clientId: string;
  close(): void;
  onSuccess(): void;
}

interface PreviewableInvoice extends ProformaInvoiceModel {
  preview?: boolean;
}

interface PreviewState {
  preview: boolean;
  pdfUrl?(): Promise<string>;
}

const ProformaInvoiceModalForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const closePopup = props.close;
  const [preview, setPreview] = useState<PreviewState>({ preview: false });
  const [form, setForm] = useState(ProformaInvoiceModel.defaultValue);

  useEffect(() => {
    ClientModel.findOne(props.clientId).then((client) => {
      if (client.validFrom && client.validTo) {
        setForm(
          ProformaInvoiceModel.withDates(client.validFrom, client.validTo)
        );
      }
    });
  }, [props.clientId]);

  return (
    <div>
      <Dialog
        open={true}
        onClose={closePopup}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Išankstinės sąskaitos forma
        </DialogTitle>
        <Formik
          initialValues={form}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            if (values.preview) {
              setForm(values);
              setPreview({
                preview: true,
                pdfUrl: () =>
                  ProformaInvoiceModel.pdfPreview(values, props.clientId),
              });
              actions.setSubmitting(false);
            } else {
              return ProformaInvoiceModel.save(values, props.clientId).then(
                (id) => {
                  closePopup();
                  props.onSuccess();
                }
              );
            }
          }}
          validationSchema={ProformaInvoiceModel.schema}
        >
          {(props: FormikProps<PreviewableInvoice>) => {
            const {
              values,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <DialogContent>
                  <Grid
                    container
                    justify="space-around"
                    direction="row"
                    spacing={2}
                  >
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <TazDatePicker
                        id="date"
                        name="date"
                        label="Data"
                        onChange={handleChange}
                        error={Boolean(errors.date)}
                        helperText={errors.date}
                        value={values.date}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <TazDatePicker
                        id="periodFrom"
                        name="periodFrom"
                        label="Prenumerata nuo"
                        onChange={handleChange}
                        error={Boolean(errors.periodFrom)}
                        helperText={errors.periodFrom}
                        value={values.periodFrom}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <TazDatePicker
                        id="periodTo"
                        name="periodTo"
                        label="Prenumerata iki"
                        onChange={handleChange}
                        error={Boolean(errors.periodTo)}
                        helperText={errors.periodTo}
                        value={values.periodTo}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <NumberFormat
                        customInput={TextField}
                        name="price"
                        id="price"
                        label="Kaina"
                        value={values.price ?? ""}
                        fullWidth
                        allowedDecimalSeparators={[".", ","]}
                        helperText={errors.price}
                        error={Boolean(errors.price)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <TextField
                        name="jobTitle"
                        id="jobTitle"
                        label="Išrašęs asmuo:"
                        value={values.jobTitle}
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      setFieldValue("preview", true);
                    }}
                  >
                    Peržiūrėti sąskaitą
                  </Button>
                  <Button
                    type="button"
                    className="outline"
                    onClick={closePopup}
                    disabled={isSubmitting}
                  >
                    Atšaukti
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => setFieldValue("preview", false)}
                    variant="contained"
                    color="primary"
                  >
                    Išsaugoti
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      {preview && preview.pdfUrl && (
        <InvoiceModalView
          close={() => setPreview({ preview: false })}
          pdfUrl={preview.pdfUrl}
        />
      )}
    </div>
  );
};

export default ProformaInvoiceModalForm;
