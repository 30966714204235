import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import AttachmentModel from "../../core/api/email/EmailAttachmentModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      minHeight: "50px",
      maxHeight: "50px",
    },
    dropzoneText: {
      marginTop: "4px",
      fontSize: "20px",
    },
  })
);
interface Props {
  name: string;
  id: string;
  value: string[];
  onChange: (name: string, attachments: string[]) => void;
}

interface CustomFileObject extends FileObject {
  id?: string;
}

const FileSelector: React.FC<Props> = ({ name, onChange }) => {
  const [files, setFiles] = useState<CustomFileObject[]>([]);
  const classes = useStyles();
  useEffect(() => {
    onChange(
      name,
      files.map((f) => f.id as string)
    );
  }, [files, name, onChange]);

  return (
    <DropzoneAreaBase
      dropzoneParagraphClass={classes.dropzoneText}
      dropzoneClass={classes.dropzone}
      fileObjects={files}
      showFileNames={true}
      showPreviews={true}
      useChipsForPreview={true}
      previewText={"Pridėti failai:"}
      dropzoneText={"Įtempkite arba pasirinkite failą"}
      getFileLimitExceedMessage={(filesLimit) => {
        return `Viršytas maksimalus failų skaičius. Liestini ${filesLimit} failai`;
      }}
      getFileAddedMessage={(fileName) => {
        return `Failas ${fileName} sėkmingai pridėtas`;
      }}
      getFileRemovedMessage={(fileName) => {
        return `Failas ${fileName} sėkmingai pašalintas`;
      }}
      showPreviewsInDropzone={false}
      onAdd={async (newFiles) => {
        const storedFiles = newFiles.map(async (file) => {
          const id = await AttachmentModel.storeAttachment(file);
          return { file: file.file, data: file.data, id: id };
        });
        const stored = await Promise.all(storedFiles);
        setFiles([...files, ...stored]);
      }}
      onDelete={async (deleteFileObj) => {
        const customFile = deleteFileObj as CustomFileObject;
        const filetered = files.filter((t) => t.id !== customFile.id);
        if (customFile.id) {
          await AttachmentModel.deleteAttachment(customFile.id);
        }
        setFiles([...filetered]);
      }}
    />
  );
};

export default FileSelector;
