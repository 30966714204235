import React from "react";
import { Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import { Home } from "./Home";
import { ClientsList } from "./clients/ClientsList";
import { Email } from "./email/Email";
import { Invoices } from "./Invoices";
import { Managers } from "./managers/ManagersList";
import ClientView from "./clients/ClientView";
import { ManagerView } from "./managers/ManagerView";
import { Statistics } from "./Statistics";
import { useAuth } from "../hooks/AuthProvider";
import EmailResultView from "./email/EmailResultView";
import { EmailStatistics } from "./EmaillStatistics";

export const ProtectedRoutes: React.FC = () => {
  const auth = useAuth();
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={Home} />
      <ProtectedRoute path="/clients" component={ClientsList} />
      <ProtectedRoute path="/client/:clientId?" component={ClientView} />
      <ProtectedRoute path="/emails/:resultId" component={EmailResultView} />
      <ProtectedRoute path="/emails" component={Email} />
      <ProtectedRoute path="/email-statistics" component={EmailStatistics} />
      <ProtectedRoute path="/invoices" component={Invoices} />
      <ProtectedRoute path="/managers-view" component={ManagerView} />
      {auth.isAdmin && <ProtectedRoute path="/managers" component={Managers} />}
      {auth.isAdmin && (
        <ProtectedRoute path="/statistics" component={Statistics} />
      )}
      <Redirect to={{ pathname: "/not-found" }} />
    </Switch>
  );
};
