import React from "react";
import { useContext, FC } from "react";
import NotificationSystem from "react-notification-system";
export interface NotificationsState {
  notify: (n: NotificationSystem.Notification) => void;
}

const initialState: NotificationsState = {
  notify: (n: NotificationSystem.Notification) => { },
};

const NotificationContext = React.createContext<NotificationsState>(
  initialState
);

export const NotificationProvider: FC = ({ children }) => {
  const notificationSystem = React.createRef<any>();

  function notify(n: NotificationSystem.Notification) {
    if (notificationSystem.current) {
      notificationSystem.current.addNotification(n);
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        notify: notify,
      }}
    >
      {children}
      <NotificationSystem ref={notificationSystem} />
    </NotificationContext.Provider>
  );
};

export function useNotifications() {
  return useContext(NotificationContext);
}
