import { format } from "date-fns";
import qs from "qs";
import * as Yup from "yup";
import ClientFilter from "../clients/ClientFilter";
import httpClient from "../config/HttpClient";
import ProformaInvoiceModel from "../invoice/ProformaInvoiceModel";
import PageRequest from "../PageRequest";
import PageResponse from "../PageResponse";
import EmailModel from "./EmailModel";

const EMAIL_URL = "/emails";

export interface EmailResults {
  id: string;
  senderName: string;
  sentCount: number;
  failedCount: number;
  status: string;
  finishedAt: Date;
  invoiceSent: boolean;
  filter: ClientFilter;
}

export interface EmailRecipient {
  clientId: string;
  email: string;
  status: string;
  createdAt: Date;
  errorMessage: string;
  opened: boolean;
}

class BatchEmailModel {
  content!: EmailModel;
  withInvoice!: boolean;
  invoice!: ProformaInvoiceModel;

  static defaultValue = (): BatchEmailModel => {
    return {
      content: EmailModel.defaultValue,
      withInvoice: false,
      invoice: ProformaInvoiceModel.defaultValue,
    };
  };

  static schema = Yup.object().shape({
    content: EmailModel.schema,
    withInvoice: Yup.boolean(),
    invoice: Yup.object().when("withInvoice", {
      is: true,
      then: ProformaInvoiceModel.schema,
    }),
  });

  static sendBatch = async (content: BatchEmailModel, filter: ClientFilter) => {
    let invoice = undefined;
    if (content.withInvoice) {
      invoice = content.invoice;
    }
    if (filter.state === "") {
      filter.state = null;
    }
    return await httpClient
      .post(EMAIL_URL + "/send", {
        content: content.content,
        invoice: invoice,
        filter: filter,
      })
      .then((resp) => resp.data);
  };

  static sentEmails = async (page: PageRequest) => {
    return await httpClient
      .get<PageResponse<EmailResults>>(EMAIL_URL, {
        params: {
          ...page,
        },
      })
      .then((resp) => resp.data as PageResponse<EmailResults>);
  };

  static emailRecipients = async (
    resultId: string,
    page: PageRequest,
    filter: any
  ) => {
    return await httpClient
      .get<PageResponse<EmailRecipient>>(
        EMAIL_URL + "/" + resultId + "/recipients",
        {
          params: {
            ...page,
            ...filter,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
            });
          },
        }
      )
      .then((resp) => resp.data as PageResponse<EmailRecipient>);
  };

  static allEmailRecipients = async (page: PageRequest, filter: any) => {
    return await httpClient
      .get<PageResponse<EmailRecipient>>(EMAIL_URL + "/recipients", {
        params: {
          ...page,
          ...filter,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
          });
        },
      })
      .then((resp) => resp.data as PageResponse<EmailRecipient>);
  };
}
export default BatchEmailModel;
