import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  TextField,
  Paper,
  InputLabel,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import ClientModel from "../../core/api/clients/ClientModel";
import { useHistory } from "react-router-dom";
import ManagersSelectInput from "../classifier/ManagersSelectInput";
import ClientStateSelectInput from "../classifier/ClientStateSelectInput";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { TazDatePicker } from "../dates/TazDatePicker";
import { useNotifications } from "../../hooks/NotificationProvider";

interface Props {
  clientId?: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    input: {
      width: "100%",
    },
    margin: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    dateInput: {
      fontWeight: "bold",
    },
    lastItem: {
      marginRight: theme.spacing(2),
    },
    title: { textAlign: "center" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    formControl: {
      width: "100%",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    emailInput: {
      marginLeft: theme.spacing(1),
    },
    emailsContainer: {
      maxHeight: "150px",
      overflowX: "hidden",
      overflowY: "auto",
    },
  })
);

const ClientForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [client, setClient] = useState(ClientModel.defaultValue);
  const notifications = useNotifications();
  const history = useHistory();
  useEffect(() => {
    if (props.clientId) {
      ClientModel.findOne(props.clientId).then((c) => setClient(c));
    }
  }, [props.clientId]);

  return (
    <Paper>
      <Formik
        initialValues={client}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          ClientModel.save(values)
            .then((entityId) => {
              if (entityId) {
                history.replace(`/client/${entityId.id}`);
              }
            })
            .then(() => {
              notifications.notify({
                level: "success",
                message: "Klientas sėkmingai išsaugotas",
              });
            })
            .catch((error) => {
              const data = error.response.data;
              if (data.description) {
                notifications.notify({
                  level: "error",
                  message: data.description,
                });
              } else if (data.errors) {
                data.errors.forEach((element: any) => {
                  actions.setFieldError(element.field, element.defaultMessage);
                });
              } else {
                notifications.notify({
                  level: "error",
                  message: "Serverio klaida",
                });
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={ClientModel.schema}
      >
        {(props: FormikProps<ClientModel>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props;
          return (
            <Form className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs>
                  {values.id && (
                    <TextField
                      className={classes.margin}
                      id="id"
                      type="text"
                      disabled
                      name="id"
                      label="Įmonės ID"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.id}
                      variant="outlined"
                    />
                  )}
                  <TextField
                    className={classes.margin}
                    error={Boolean(errors.companyTitle)}
                    id="companyTitle"
                    type="text"
                    name="companyTitle"
                    label="Įmonės pavadinimas"
                    fullWidth
                    onChange={handleChange}
                    helperText={errors.companyTitle}
                    value={values.companyTitle}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.margin}
                    error={Boolean(errors.companyCode)}
                    id="companyCode"
                    type="text"
                    name="companyCode"
                    label="Įmonės kodas"
                    onChange={handleChange}
                    fullWidth
                    helperText={errors.companyCode}
                    value={values.companyCode}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.margin}
                    error={Boolean(errors.companyVatCode)}
                    id="companyVatCode"
                    type="text"
                    name="companyVatCode"
                    label="Įmonės PVM mokėtojo kodas"
                    fullWidth
                    onChange={handleChange}
                    helperText={errors.companyVatCode}
                    value={values.companyVatCode}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.margin}
                    error={Boolean(errors.companyAddress)}
                    id="companyAddress"
                    type="text"
                    name="companyAddress"
                    label="Įmonės adresas"
                    onChange={handleChange}
                    fullWidth
                    helperText={errors.companyAddress}
                    value={values.companyAddress}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    className={classes.margin}
                    error={Boolean(errors.addressee)}
                    id="addressee"
                    type="text"
                    name="addressee"
                    label="Įmonės adresatas"
                    onChange={handleChange}
                    helperText={errors.addressee}
                    fullWidth
                    value={values.addressee}
                    onBlur={handleBlur}
                    variant="outlined"
                  />

                  <TextField
                    className={classes.margin}
                    error={Boolean(errors.companyPhone)}
                    id="companyPhone"
                    type="text"
                    name="companyPhone"
                    label="Telefono numeris"
                    onChange={handleChange}
                    helperText={errors.companyPhone}
                    fullWidth
                    value={values.companyPhone}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                  <InputLabel id="emailsLabel-label" className={classes.margin}>
                    El. pašto adresai
                  </InputLabel>
                  <div className={classes.emailsContainer}>
                    <FieldArray
                      name="emails"
                      render={(arrayHelpers) => (
                        <div>
                          {values.emails.map((email, index) => (
                            <div key={index}>
                              <TextField
                                className={classes.emailInput}
                                size="small"
                                // variant="outlined"
                                id={`emails[${index}]`}
                                type="text"
                                name={`emails[${index}]`}
                                error={
                                  errors.emails && touched.emails ? true : false
                                }
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={email}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton
                                      color="secondary"
                                      aria-label="Išimti adresą"
                                      component="span"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  ),
                                }}
                              />
                            </div>
                          ))}
                          <IconButton
                            color="primary"
                            aria-label="Pridėti adresą"
                            component="span"
                            onClick={() => arrayHelpers.push("")}
                          >
                            <AddIcon />
                          </IconButton>
                          {errors.emails && touched.emails && (
                            <FormHelperText error>
                              {errors.emails}
                            </FormHelperText>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={3} className={classes.lastItem}>
                  <ClientStateSelectInput
                    className={classes.margin}
                    name="clientState"
                    labelId="user-responsible-label"
                    id="user-type-select"
                    value={values.clientState}
                    label="Kliento statusas"
                    error={Boolean(errors.clientState)}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    variant="outlined"
                  ></ClientStateSelectInput>
                  <TazDatePicker
                    className={classes.margin}
                    id="validFrom"
                    name="validFrom"
                    label="Prenumerata galioja nuo"
                    onChange={handleChange}
                    helperText={errors.validFrom}
                    value={values.validFrom}
                    onBlur={handleBlur}
                    variant="outlined"
                    InputProps={{
                      className: classes.dateInput,
                    }}
                  />
                  <TazDatePicker
                    className={classes.margin}
                    id="validTo"
                    name="validTo"
                    label="Prenumerata galioja iki"
                    onChange={handleChange}
                    value={values.validTo}
                    onBlur={handleBlur}
                    variant="outlined"
                    InputProps={{
                      className: classes.dateInput,
                    }}
                  />
                  <ManagersSelectInput
                    className={classes.margin}
                    name="responsibleUser"
                    labelId="user-responsible-label"
                    id="user-responsible-select"
                    value={values.responsibleUser}
                    label="Atsakingas asmuo"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                  ></ManagersSelectInput>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    className={classes.margin}
                    id="comment"
                    type="text"
                    name="comment"
                    label="Komentaras"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    value={values.comment}
                    onBlur={handleBlur}
                    multiline
                    rowsMax={9}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container justify="flex-end">
                  <Button
                    type="submit"
                    className={classes.margin}
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                  >
                    Išsaugoti
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default ClientForm;
