import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
export interface PrivateRouteProps extends RouteProps {
  role?: String;
}

const ProtectedRoute: React.FC<PrivateRouteProps> = (props) => {
  return <Route {...props} component={props.component} render={undefined} />;
};

export default ProtectedRoute;
