import React from "react";
import { Dialog, DialogTitle, Grid, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ManagersModalForm from "../../components/managers/ManagersModalForm";
import ManagersModel from "../../core/api/manager/ManagersModel";
import MaterialTable from "material-table";
import Edit from "@material-ui/icons/Edit";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { Delete } from "@material-ui/icons";
import { icons } from "../../components/TableIcons";
import useErrorAsync from "../../hooks/useErrorAsync";
import customAlert from "../../components/layout/ConfirmDialog";
import { useNotifications } from "../../hooks/NotificationProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      padding: theme.spacing(2),
    },
  })
);

export const Managers: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [manager, setManager] = React.useState(ManagersModel.defaultValue);
  const notifications = useNotifications();
  const { data, reload } = useErrorAsync({
    promiseFn: ManagersModel.managersList,
  });

  const handleNewManager = () => {
    setManager(ManagersModel.defaultValue);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reload();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const actions = [
    {
      icon: VpnKeyIcon,
      tooltip: "Atstatyti slaptažodį",
      onClick: (event: any, rowData: any) => {
        customAlert({
          action: () => {
            ManagersModel.resetPassword(rowData.id).then(() => {
              notifications.notify({
                title: "Atstatyta",
                message: "Slaptažodžio atstatymo laiškas išsiųstas",
                level: "success",
              });
            });
          },
          actionName: "Atstatyti",
          body: `Ar tikrai atstatyti ${rowData.firstName} slaptažodį?`,
          title: "Atstatyti slaptažodį?",
        });
      },
    },
    {
      icon: Edit,
      tooltip: "Redaguoti vadybininką",
      onClick: (event: any, rowData: any) => {
        setManager(rowData);
        handleOpen();
      },
    },
    {
      icon: Delete,
      tooltip: "Ištrinti vadybininką",
      onClick: (event: any, rowData: any) => {
        customAlert({
          action: async () => {
            ManagersModel.delete(rowData).then(() => {
              notifications.notify({
                title: "Ištrinta",
                message: "Vadybininkas ištrintas sėkmingai",
                level: "success",
              });
              reload();
            });
          },
          actionName: "Ištrinti",
          body: `Ar tikrai norite ištrinti ${rowData.firstName} ${rowData.lastName} iš sistemos?`,
          title: "Ištrinti vadybininką?",
        });
      },
    },
    {
      icon: () => (
        <Typography variant="button" display="block" color="primary">
          Naujas vadybininkas
        </Typography>
      ),
      tooltip: "Naujas vadybininką",
      isFreeAction: true,
      onClick: (event: any, rowData: any) => {
        handleNewManager();
      },
    },
  ];

  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.content}
      alignItems="flex-start"
      justify="flex-end"
      direction="row"
      spacing={3}
    >
      <Grid item xs>
        <MaterialTable
          icons={icons}
          columns={[
            { title: "Vartotojo Vardas", field: "username" },
            { title: "Vardas", field: "firstName" },
            { title: "Pavardė", field: "lastName" },
            { title: "El. paštas", field: "email" },
            {
              title: "Vartotojo tipas",
              field: "userType",
              lookup: { ADMIN: "Administratorius", USER: "Vadybininkas" },
            },
            {
              title: "Statusas",
              field: "active",
              lookup: { true: "Aktyvus", false: "Neaktyvus" },
            },
          ]}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10],
            paginationType: "stepped",
            rowStyle: (rowData) => ({
              backgroundColor: rowData.tableData.active ? "#EEE" : "#FFF",
            }),
          }}
          localization={{
            header: {
              actions: "",
            },
          }}
          actions={actions}
          data={data || []}
          title="Vadybininkai"
        />
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Vadybininko forma</DialogTitle>
        <ManagersModalForm formValues={manager} onSuccess={handleClose} />
      </Dialog>
    </Grid>
  );
};
