import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import MonthlyIncome from "../../core/api/statistics/MonthlyIncome";
import { monthlyIncome } from "../../core/api/statistics/StatisticsService";

const IncomeLineChart: React.FC = () => {
  const [data, setData] = useState<MonthlyIncome[]>([]);

  useEffect(() => {
    monthlyIncome().then((r) => setData(r));
  }, []);

  return (
    <Grid
      component={Paper}
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
    >
      <Grid item>
        <Typography variant="h6">Mėnesinės pajamos</Typography>
      </Grid>
      <ResponsiveContainer height={400} width="100%">
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="totalPrice"
            stroke="#8884d8"
            fill="#8884d8"
          />
          <Line
            type="monotone"
            dataKey="totalPvm"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
          <Line type="monotone" dataKey="sum" stroke="#ffc658" fill="#ffc658" />
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );
};

export default IncomeLineChart;
