import axios from "axios";
import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import { LoginForm, UserDetails } from "../../../hooks/AuthProvider";
import { LOGIN_URL, LOGOUT_URL } from "../routes";

let requestInterceptorId: number | null = null;
let refreshFailed = false;
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
});

const baseClient = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
});

const config = {
  headers: {
    Authorization: "Basic dmVyeTpzZWNyZXQ=",
    "Content-Type": "multipart/form-data",
  },
  skipAuthRefresh: true,
} as AxiosAuthRefreshRequestConfig;

export const addAuthInterceptor = (accessToken: string | null) => {
  removeAuthInterceptor();
  if (accessToken) {
    requestInterceptorId = httpClient.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          Authorization: "Bearer " + accessToken,
        };
        return config;
      },
      (response) => {
        return Promise.reject(response);
      }
    );
  }
};

export const addRefreshInterceptor = (onFailure: () => Promise<void>) => {
  httpClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config, response } = error;
      if (!refreshFailed && config && response && response.status === 401) {
        const refreshedToken = await refreshToken();
        if (!refreshedToken) {
          await onFailure();
          refreshFailed = true;
          return Promise.reject(error);
        }
        config.headers = {
          ...config.headers,
          Authorization: "Bearer " + refreshedToken.access_token,
        };
        addAuthInterceptor(refreshedToken.access_token);
        return baseClient.request(config);
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export const removeAuthInterceptor = () => {
  if (requestInterceptorId) {
    httpClient.interceptors.request.eject(requestInterceptorId);
    requestInterceptorId = null;
  }
};

export const login = async (form: LoginForm) => {
  const data = new FormData();
  data.append("username", form.username);
  data.append("password", form.password);
  data.append("grant_type", form.grant_type);
  const resp = await baseClient.post<UserDetails>(LOGIN_URL, data, config);
  return resp.data as UserDetails;
};

export const refreshToken = async () => {
  const refreshRequest = new FormData();
  refreshRequest.append("grant_type", "refresh_token");
  const resp = await baseClient.post<UserDetails>(
    LOGIN_URL,
    refreshRequest,
    config
  );
  return resp.data as UserDetails;
};

export const logout = async () => {
  return baseClient.post(LOGOUT_URL);
};

export default httpClient;
