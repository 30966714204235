// Auth
export const LOGIN_URL = "/oauth/token";
export const LOGOUT_URL = "/oauth/logout";
// Managers
export const MANAGERS_URL = "/users";
export const MANAGERS_DETAILS = "/users/details";
export const RESET_PASSWORD_URL = "/users/remember/reset";
//Clients
export const CLIENTS_URL = "/clients";
//Reminders
export const REMINDERS_URL = "/reminders";
//Clasifiers
export const CLASSIFIER_URL = "/classifiers"