import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { MaterialTableProps } from "material-table";
import InvoiceModel, {
  InvoiceCreateModel,
} from "../../core/api/invoice/InvoiceModel";
import { TazDatePicker } from "../dates/TazDatePicker";
import InvoiceModalView from "./InvoiceModalView";
import ClientModel from "../../core/api/clients/ClientModel";
import { useNotifications } from "../../hooks/NotificationProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      "& > *": {
        width: "100%",
      },
    },
    submitButton: {
      marginTop: "24px",
    },
    title: { textAlign: "center" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface Props {
  clientId: string;
  close(): void;
  onSuccess(id: string): void;
  tableRef: React.RefObject<MaterialTableProps<any>>;
}

interface PreviewableInvoice extends InvoiceCreateModel {
  preview?: boolean;
}

interface PreviewState {
  preview: boolean;
  pdfUrl?(): Promise<string>;
}

const InvoiceModalForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const closePopup = props.close;
  const notifications = useNotifications();
  const [form, setForm] = useState<PreviewableInvoice>(
    InvoiceCreateModel.defaultValue(props.clientId)
  );

  useEffect(() => {
    ClientModel.findOne(props.clientId).then((client) => {
      if (client.validFrom && client.validTo) {
        setForm(
          InvoiceCreateModel.withDates(
            props.clientId,
            client.validFrom,
            client.validTo
          )
        );
      }
    });
  }, [props.clientId]);
  const [preview, setPreview] = useState<PreviewState>({ preview: false });
  return (
    <div>
      <Dialog
        open={true}
        onClose={closePopup}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Sąskaitos forma</DialogTitle>
        <Formik
          initialValues={form}
          enableReinitialize={true}
          validationSchema={InvoiceCreateModel.createSchema}
          onSubmit={(values, actions) => {
            if (values.preview) {
              setForm(values);
              setPreview({
                preview: true,
                pdfUrl: () => InvoiceCreateModel.pdfPreview(values),
              });
              actions.setSubmitting(false);
            } else {
              InvoiceModel.create(values)
                .then((body) => {
                  closePopup();
                  notifications.notify({
                    level: "success",
                    message: "Sąskaita išsaugota sėkmingai",
                  });
                  props.onSuccess(body.id);
                })
                .then(() => {
                  const ref = props.tableRef.current as any;
                  ref.onQueryChange();
                })
                .catch((error) => {
                  const data = error.response.data;
                  if (data.errors) {
                    data.errors.forEach((element: any) => {
                      actions.setFieldError(
                        element.field,
                        element.defaultMessage
                      );
                    });
                  }
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }
          }}
        >
          {(props: FormikProps<PreviewableInvoice>) => {
            const {
              values,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <DialogContent>
                  <Grid
                    container
                    justify="space-around"
                    direction="row"
                    spacing={2}
                  >
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <TazDatePicker
                        id="date"
                        name="date"
                        label="Data"
                        onChange={handleChange}
                        error={Boolean(errors.date)}
                        helperText={errors.date}
                        value={values.date}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <TazDatePicker
                        id="periodFrom"
                        name="periodFrom"
                        label="Prenumerata nuo"
                        error={Boolean(errors.periodFrom)}
                        helperText={errors.periodFrom}
                        onChange={handleChange}
                        value={values.periodFrom}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <TazDatePicker
                        id="periodTo"
                        name="periodTo"
                        label="Prenumerata iki"
                        error={Boolean(errors.periodTo)}
                        helperText={errors.periodTo}
                        onChange={handleChange}
                        value={values.periodTo}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <NumberFormat
                        customInput={TextField}
                        name="price"
                        id="price"
                        label="Kaina"
                        value={values.price ?? ""}
                        fullWidth
                        allowedDecimalSeparators={[".", ","]}
                        helperText={errors.price}
                        error={Boolean(errors.price)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <NumberFormat
                        customInput={TextField}
                        name="discount"
                        id="discount"
                        label="Nuolaida"
                        value={values.discount ?? ""}
                        fullWidth
                        allowedDecimalSeparators={[".", ","]}
                        helperText={errors.discount}
                        error={Boolean(errors.discount)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.textField}
                    >
                      <TextField
                        name="jobTitle"
                        id="jobTitle"
                        label="Išrašęs asmuo:"
                        value={values.jobTitle}
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      setFieldValue("preview", true);
                    }}
                  >
                    Peržiūrėti sąskaitą
                  </Button>
                  <Button
                    type="button"
                    className="outline"
                    onClick={closePopup}
                    disabled={isSubmitting}
                  >
                    Atšaukti
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => setFieldValue("preview", false)}
                    variant="contained"
                    color="primary"
                  >
                    Išsaugoti
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      {preview && preview.pdfUrl && (
        <InvoiceModalView
          close={() => setPreview({ preview: false })}
          pdfUrl={preview.pdfUrl}
        />
      )}
    </div>
  );
};

export default InvoiceModalForm;
