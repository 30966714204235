import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import ManagersForm from "../../components/managers/ManagersForm";
import SubjectsTable from "../../components/managers/subjects/SubjectsTable";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    }
  })
);

export const ManagerView: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="stretch"
      justify="flex-start"
      direction="column"
      spacing={3}
      className={classes.root}
    >
      <Grid item xs={12}>
        <ManagersForm />
      </Grid>
      <Grid item xs={12}>
        <SubjectsTable />
      </Grid>
    </Grid>
  );
};
