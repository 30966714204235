import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { ProtectedRoutes } from "../../routes/ProtectedRoutes";
import background from "../../assets/background.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // necessary for content to be below app bar
    content: {
      marginLeft: 200,
    },
    paper: {
      margin: theme.spacing(1),
    },
    backGround: {
      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      minWidth: "100%",
      minHeight: "100%",
      margin: 0,
      padding: 0,
      opacity: 1,
    },
  })
);

export const Layout: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.backGround}>
      <CssBaseline />
      <Sidebar />
      <div className={classes.content}>
        <div>
          <Header />
        </div>
        <div className={classes.paper}>
          <ProtectedRoutes />
        </div>
      </div>
    </div>
  );
};
