import React from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { TazDatePicker } from "../dates/TazDatePicker";
import ManagersSelectInput from "../classifier/ManagersSelectInput";
import EmailFilterModel from "../../core/api/email/EmailFilterModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    formControl: {
      minWidth: 200,
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  filter: EmailFilterModel;
  onFilterChange: (filter: EmailFilterModel) => void;
}

const EmailFilterForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const onClear = () => {
    props.onFilterChange({
      dateFrom: "",
      dateTo: "",
      user: "",
      status: "",
      email: "",
      clientId: "",
      opened: "",
    });
  };

  return (
    <Formik
      initialValues={props.filter}
      enableReinitialize
      onSubmit={(values, actions) => {
        props.onFilterChange(values);
        actions.setSubmitting(false);
      }}
    >
      {(props: FormikProps<EmailFilterModel>) => {
        const { values, handleChange, isSubmitting } = props;
        return (
          <Form>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs>
                <TextField
                  className={classes.margin}
                  fullWidth
                  id="email"
                  type="text"
                  name="email"
                  label="El. paštas"
                  onChange={handleChange}
                  value={values.email}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  className={classes.margin}
                  id="clientId"
                  fullWidth
                  type="number"
                  name="clientId"
                  label="Kliento ID"
                  onChange={handleChange}
                  value={values.clientId}
                />
              </Grid>
              <Grid item xs>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="email-status-label" shrink>
                    Statusas
                  </InputLabel>
                  <Select
                    name="status"
                    labelId="email-status-label"
                    id="status"
                    value={values.status}
                    label="Statusas"
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value="">Nepasirinktas</MenuItem>
                    <MenuItem value="ERROR">Klaida</MenuItem>
                    <MenuItem value="SENT">Išsiųsta</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="email-status-label" shrink>
                    Peržiūretas
                  </InputLabel>
                  <Select
                    name="opened"
                    labelId="email-status-label"
                    id="opened"
                    value={values.opened}
                    label="Laiško statusas"
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value="">Visi laiškai</MenuItem>
                    <MenuItem value="true">Taip</MenuItem>
                    <MenuItem value="false">Ne</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <TazDatePicker
                  className={classes.margin}
                  id="dateFrom"
                  name="dateFrom"
                  label="Data nuo"
                  value={values.dateFrom}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TazDatePicker
                  className={classes.margin}
                  id="dateTo"
                  name="dateTo"
                  label="Data iki"
                  value={values.dateTo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <ManagersSelectInput
                  className={classes.margin}
                  name="author"
                  labelId="user-responsible-label"
                  id="author"
                  value={values.user}
                  label="Išsiuntęs asmuo"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <Button
                  className={classes.margin}
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Filtruoti
                </Button>
                <Button
                  className={classes.margin}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={onClear}
                  fullWidth
                >
                  Išvalyti
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailFilterForm;
