import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import ManagersModel from "../../core/api/manager/ManagersModel";
import { useNotifications } from "../../hooks/NotificationProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

const ManagersForm: React.FC = () => {
  const classes = useStyles();
  const [manager, setManager] = useState(ManagersModel.defaultValue);

  const notifications = useNotifications();
  useEffect(() => {
    ManagersModel.manager().then((m) => setManager(m));
  }, []);

  return (
    <Paper className={classes.root}>
      <Formik
        enableReinitialize={true}
        initialValues={manager}
        onSubmit={(values, actions) => {
          ManagersModel.updateDetails(values)
            .catch((error) => {
              const data = error.response.data;
              data.errors.forEach((element: any) => {
                actions.setFieldError(element.field, element.defaultMessage);
              });
            })
            .then(() => {
              notifications.notify({
                title: "Išsaugota",
                message: "Sėkmingai išsaugota",
                level: "success",
              });
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={ManagersModel.schema}
      >
        {(props: FormikProps<ManagersModel>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props;
          return (
            <Form>
              <Grid container direction="column" spacing={3} >
                <Grid item xs>
                  <Typography variant="h5" gutterBottom>
                    Asmeninė informacija
                </Typography>
                </Grid>
                <Grid item xs>
                  <TextField
                    className={classes.margin}
                    error={errors.firstName && touched.firstName ? true : false}
                    id="firstName"
                    type="text"
                    name="firstName"
                    label="Vardas"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    helperText={
                      touched.firstName && errors.firstName
                        ? errors.firstName
                        : ""
                    }
                    value={values.firstName}
                    onBlur={handleBlur}
                  />
                  <TextField
                    className={classes.margin}
                    error={errors.lastName && touched.lastName ? true : false}
                    id="lastName"
                    type="text"
                    name="lastName"
                    label="Pavardė"
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    helperText={
                      touched.lastName && errors.lastName ? errors.lastName : ""
                    }
                    value={values.lastName}
                    onBlur={handleBlur}
                  />
                  <TextField
                    className={classes.margin}
                    error={errors.email && touched.email ? true : false}
                    id="email"
                    type="text"
                    name="email"
                    label="El. pašto adresas"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    helperText={touched.email && errors.email ? errors.email : ""}
                    value={values.email}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                  >
                    Išsaugoti
                </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default ManagersForm;
