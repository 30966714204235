import { Grid, Typography } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import MaterialTable from "material-table";
import React from "react";
import BatchEmailModel, {
  EmailResults,
} from "../../core/api/email/BatchEmailModel";
import { icons } from "../TableIcons";
import GroupIcon from "@material-ui/icons/Group";
import { useHistory } from "react-router-dom";

const STATES = new Map([
  ["PAST", "Buvęs"],
  ["ACTIVE", "Aktyvus"],
  ["DEAD", "Neaktyvus"],
  ["HIDDEN", "Paslėptas"],
  ["SPECIAL", "Ypatingas"],
  ["COMATOSE", "Tarpinis"],
  ["OTHER", "Kitas"],
]);

const EmailResultsTable: React.FC = () => {
  const history = useHistory();

  return (
    <MaterialTable
      icons={icons}
      columns={[
        { title: "Siuntėjas", field: "senderName" },
        {
          title: "Filtras",
          render: (rowData) => {
            const results = rowData as EmailResults;
            return (
              <Grid>
                <Typography>Filtras: {results.filter.filter}</Typography>
                <Typography>
                  Statusas: {states(results.filter.state)}
                </Typography>
                <Typography>
                  Prenumeratos pabaiga nuo: {results.filter.validFrom}
                </Typography>
                <Typography>
                  Prenumeratos pabaiga iki: {results.filter.validTo}
                </Typography>
                <Typography>Id nuo: {results.filter.idFrom}</Typography>
                <Typography>Id iki: {results.filter.idTo}</Typography>
              </Grid>
            );
          },
        },
        {
          title: "Statusas",
          field: "status",
          lookup: { PROCESSING: "Siunčiamas", FINISHED: "Išsiųstas" },
        },

        {
          title: "Siuntimo pabaigos laikas",
          field: "finishedAt",
          render: (rowData) => {
            if (rowData.finishedAt) {
              const date = parseISO(rowData.finishedAt);
              return <span>{format(date, "yyyy-MM-dd HH:mm")}</span>;
            }
            return <span></span>;
          },
        },
        { title: "Išsiųsti laiškai", field: "sentCount" },
        { title: "Neišsiųsti laiškai", field: "failedCount" },
        {
          title: "Sąskaita siųsta",
          field: "invoiceSent",
          lookup: {
            true: "Taip",
            false: "Ne",
          },
        },
      ]}
      actions={[
        {
          icon: GroupIcon,
          tooltip: "Peržiūrėti gavėjus",
          onClick: (event: any, rowData: any) => {
            history.push({
              pathname: `/emails/${rowData.id}`,
            });
          },
        },
      ]}
      options={{
        sorting: false,
        search: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 20],
        paginationType: "stepped",
        headerStyle: {
          backgroundColor: "#bdbdbd",
        },
        rowStyle: (rowData, index) => {
          if (index % 2) {
            return {};
          }
          return { backgroundColor: "#efefef" };
        },
      }}
      localization={{
        header: {
          actions: "",
        },
      }}
      data={(query) =>
        new Promise((resolve, reject) => {
          BatchEmailModel.sentEmails({
            page: query.page,
            size: query.pageSize,
          }).then((result) => {
            resolve({
              data: result.content,
              page: result.number,
              totalCount: result.totalElements,
            });
          });
        })
      }
      title="Išsiųsti laiškai"
    />
  );
};

function states(state: string | null) {
  if (state) {
    return STATES.has(state) ? STATES.get(state) : "";
  }
  return "";
}

export default EmailResultsTable;
