import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Cell,
  Legend,
  LegendPayload,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts";
import ClassifierModel from "../../core/api/ClassifierModel";
import ClientCount from "../../core/api/statistics/ClientsCount";
import { clientCounts } from "../../core/api/statistics/StatisticsService";

const COLORS = new Map([
  ["PAST", "#b22222"],
  ["ACTIVE", "#32cd32"],
  ["DEAD", "#191970"],
  ["HIDDEN", "#96cdcd"],
  ["SPECIAL", "#eec900"],
  ["COMATOSE", "#FF00D8"],
  ["OTHER", "#eeb422"],
]);

const ClientCountsChart: React.FC = () => {
  const [data, setData] = useState<ClientCount[]>([]);
  const [labels, setLabels] = useState<LegendPayload[]>([]);

  function color(state: string) {
    return COLORS.has(state) ? COLORS.get(state) : "#f5fffa";
  }

  useEffect(() => {
    async function loadData() {
      const data = await clientCounts();
      const labels = await ClassifierModel.clientStates().then((states) =>
        states.map((s) => {
          return {
            key: s.id,
            value: s.name,
            id: s.id,
            type: "circle",
            color: color(s.id),
          } as LegendPayload;
        })
      );
      const total = data.map((c) => c.count).reduce((a, b) => a + b);
      labels.push({
        id: "total",
        type: "diamond",
        value: `Iš viso : ${total}`,
      });
      setLabels(labels);
      setData(data);
    }
    loadData();
  }, []);

  return (
    <Grid
      component={Paper}
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
    >
      <Grid item>
        <Typography variant="h6">Klientų statistika</Typography>
      </Grid>
      <ResponsiveContainer height={500} width="100%">
        <PieChart>
          <Pie
            dataKey="count"
            data={data}
            labelLine={false}
            outerRadius="85%"
            fill="#8884d8"
            label
          >
            {data.map((entry, index) => (
              <Cell key={entry.state} fill={color(entry.state)} />
            ))}
          </Pie>
          <Legend
            verticalAlign="top"
            align="right"
            height={36}
            layout="vertical"
            payload={labels}
          />
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  );
};

export default ClientCountsChart;
