import React from "react";
import { Grid } from "@material-ui/core";
import ClientCountsChart from "../components/statistics/ClientCountsChart";
import ActiveClientCountsTable from "../components/statistics/ActiveClientCountsTable";
import IncomeLineChart from "../components/statistics/IncomeLineChart";
import IncomeTable from "../components/statistics/IncomeTable";

export const Statistics: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item md={5} sm={12}>
        <ClientCountsChart />
      </Grid>
      <Grid item md={3} sm={12}>
        <ActiveClientCountsTable />
      </Grid>
      <Grid item md={4} sm={12}>
        <IncomeTable />
      </Grid>
      <Grid item md={12} sm={12}>
        <IncomeLineChart />
      </Grid>
    </Grid>
  );
};
