import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/AuthProvider";
import { AppRoutes } from "./routes/AppRoutes";
import ErrorBoundary from "./components/ErrorBoundary";
import { NotificationProvider } from "./hooks/NotificationProvider";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <NotificationProvider>
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        </NotificationProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
export default App;
