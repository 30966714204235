import * as Yup from "yup";
import PageRequest from "../PageRequest";
import PageResponse from "../PageResponse";
import ClientFilter from "../clients/ClientFilter";
import qs from "qs";
import { format } from "date-fns";
import EntityId from "../EntityId";
import httpClient from "../config/HttpClient";

export interface ReminderFilter {
  dateFrom: string;
  dateTo: string;
  tableFilter: string;
  companyFilter: string;
  clientState: string;
}

export interface ReminderView {
  clientId: string;
  companyTitle: string;
  date: Date;
  comment: string;
  clientState: string;
}

class ReminderModel {
  date!: Date;
  comment!: string;

  static defaultValue: ReminderModel = {
    date: new Date(),
    comment: "",
  };

  static schema = Yup.object().shape({
    date: Yup.date().required("Data yra privaloma"),
  });

  static reminder = (clientId: string) => {
    return httpClient
      .get<ReminderView>(`/clients/${clientId}/reminders`)
      .then((resp) => resp.data);
  };

  static updateOrCreate = async (clientId: string, model: ReminderModel) => {
    return await httpClient
      .put<EntityId>(`/clients/${clientId}/reminders`, model)
      .then((resp) => resp.data);
  };

  static delete = (clientId: string) => {
    return httpClient
      .delete(`/clients/${clientId}/reminders`)
      .then((resp) => resp.data);
  };

  static remindersList = (page: PageRequest, filter: ReminderFilter) =>
    httpClient
      .get<PageResponse<ReminderModel>>("/reminders", {
        params: {
          ...page,
          ...filter,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
          });
        },
      })
      .then((resp) => resp.data as PageResponse<ReminderModel>);

  static createRemindersList = (
    reminder: ReminderModel,
    filter: ClientFilter
  ) =>
    httpClient
      .post("/clients/reminders", reminder, {
        params: filter,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
          });
        },
      })
      .then((resp) => resp.data);

  static updateReminderList = (
    reminder: ReminderModel,
    filter: ReminderFilter
  ) => {
    return httpClient
      .put("/clients/reminders", reminder, {
        params: filter,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
          });
        },
      })
      .then((resp) => resp.data);
  };

  static updateReminderClientStates = (
    clientState: string,
    filter: ReminderFilter
  ) =>
    httpClient
      .put<number>(
        "/clients/reminders/update-clients",
        {
          clientState: clientState,
        },
        {
          params: filter,
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
            });
          },
        }
      )
      .then((resp) => resp.data);
}

export default ReminderModel;
