import { FileObject } from "material-ui-dropzone";
import httpClient from "../config/HttpClient";
import EntityId from "../EntityId";

class AttachmentModel {
  static storeAttachment = async (attachment: FileObject) => {
    var bodyFormData = new FormData();
    bodyFormData.append("attachment", attachment.file);
    return await httpClient
      .post<EntityId>("attachments/", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((resp) => resp.data)
      .then((resp) => resp.id);
  };

  static deleteAttachment = async (attachmentId: string) => {
    return await httpClient
      .delete<EntityId>("attachments/" + attachmentId)
      .then((resp) => resp.data)
      .then((resp) => resp.id);
  };
}
export default AttachmentModel;
