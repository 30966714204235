import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import React, { useEffect, useState } from "react";
import ClassifierModel from "../../core/api/ClassifierModel";

interface Props extends SelectProps {
  errors?: FormikErrors<any>;
  touched?: FormikTouched<any>;
}

const ClientStateSelectInput: React.FC<Props> = (props) => {
  const [userStates, setUserStates] = useState([] as ClassifierModel[]);
  useEffect(() => {
    ClassifierModel.clientStates().then((s) => {
      setUserStates(s);
    });
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel
        id="user-responsible-label"
        className={props.className}
        variant={props.variant}
      >
        Kliento statusas
      </InputLabel>

      <Select
        {...props}
        labelId="user-responsible-label"
        labelWidth={0}
        fullWidth
      >
        <MenuItem value={""}>Nepasirinktas</MenuItem>
        {userStates.map((state) => (
          <MenuItem key={state.id} value={state.id}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
      {props.errors?.clientState && props.touched?.clientState && (
        <FormHelperText error>{props.errors.clientState}</FormHelperText>
      )}
    </FormControl>
  );
};

export default ClientStateSelectInput;
