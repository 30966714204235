interface ClientFilter {
  tableFilter: string;
  filter: string;
  state: string | null;
  validFrom: string;
  validTo: string;
  ids: string[];
  idFrom: string;
  idTo: string;
  myClients: boolean;
}

export const defaultFilter = {
  tableFilter: "",
  filter: "",
  state: "",
  idFrom: "",
  idTo: "",
  validFrom: "",
  validTo: "",
  ids: [],
  myClients: false,
} as ClientFilter;

export function isEmptyFilter(filter: ClientFilter): boolean {
  return (
    filter.filter === defaultFilter.filter &&
    filter.state === defaultFilter.state &&
    filter.idFrom === defaultFilter.idFrom &&
    filter.idTo === defaultFilter.idTo &&
    filter.validFrom === defaultFilter.validFrom &&
    filter.validTo === defaultFilter.validTo &&
    (filter.ids === [] || filter.ids === null)
  );
}

export default ClientFilter;
