import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Background from "../../assets/background.png";
import PasswordResetModel from "../../core/api/PasswordResetModel";
import { useHistory } from "react-router-dom";
import { CssBaseline, Grid, Paper } from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import Logo from "../../assets/logo_alpha.png";
import { useNotifications } from "../../hooks/NotificationProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
  },
}));

interface Props {
  match?: any;
}

const PasswordReset: React.FC<Props> = (props) => {
  const initialState: PasswordResetModel = {
    code: props.match.params.token,
    password: "",
    confirmPassword: "",
  };

  const classes = useStyles();
  const history = useHistory();
  const notifications = useNotifications();
  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <CssBaseline />
      <Grid item component={Paper} className={classes.paper} xs={5}>
        <Formik
          initialValues={initialState}
          onSubmit={(values, actions) => {
            PasswordResetModel.reset(values)
              .then(() => {
                history.push("/");
                notifications.notify({
                  message: "Slaptažodis sėkmingai pakeistas",
                  level: "success",
                });
              })
              .catch((error) => {
                const data = error.response.data;

                data.errors.forEach((element: any) => {
                  actions.setFieldError(element.field, element.defaultMessage);
                });
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
          validationSchema={PasswordResetModel.schema}
        >
          {(props: FormikProps<PasswordResetModel>) => {
            const {
              values,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
            } = props;
            return (
              <Form>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item xs>
                    <div>
                      <img src={Logo} alt="Logo" />
                    </div>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id="password"
                      type="password"
                      name="password"
                      label="Slaptažodis"
                      onChange={handleChange}
                      error={Boolean(errors.password)}
                      helperText={errors.password}
                      fullWidth
                      value={values.password}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      name="confirmPassword"
                      id="confirmPassword"
                      label="Patvirtinkit slaptažodį"
                      value={values.confirmPassword}
                      type="password"
                      error={Boolean(errors.confirmPassword)}
                      helperText={errors.confirmPassword}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Pakeisti slaptažodį
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default PasswordReset;
