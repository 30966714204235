import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from "@material-ui/core";
import { useNotifications } from "../../../hooks/NotificationProvider";
import SubjectModel from "../../../core/api/manager/SubjectModel";

interface Props {
    subjectId?: string | null;
    close(): void;
}

const SubjectModalForm: React.FC<Props> = ({ subjectId, close }) => {
    const notifications = useNotifications();
    const [value, setValue] = useState<SubjectModel>(SubjectModel.defaultValue);

    useEffect(() => {
        if (subjectId) {
            SubjectModel.subject(subjectId)
                .then(s => setValue(s));
        }
    }, [subjectId])

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={true}
            onClose={close}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Laiško tema</DialogTitle>
            <Formik
                initialValues={value}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    SubjectModel.updateOrCreate(values)
                        .then(close)
                        .then(() =>
                            notifications.notify({
                                level: "success",
                                message: "Laiško tema išsaugota sėkmingai",
                            })
                        )
                        .catch((error) => {
                            const data = error.response.data;
                            if (data.errors) {
                                data.errors.forEach((element: any) => {
                                    actions.setFieldError(element.field, element.defaultMessage);
                                });
                            }
                        })
                        .finally(() => {
                            actions.setSubmitting(false);
                        });
                }}
                validationSchema={SubjectModel.schema}
            >
                {(props: FormikProps<SubjectModel>) => {
                    const {
                        values,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                    } = props;
                    return (
                        <Form>
                            <DialogContent>
                                <Grid
                                    container
                                    justify="space-around"
                                    direction="row"
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        lg={10}
                                        md={10}
                                        sm={10}
                                        xs={10}
                                    >
                                        <TextField
                                            name="subject"
                                            id="subject"
                                            label="Laiško tema"
                                            value={values.subject}
                                            type="text"
                                            multiline
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={close}
                                    disabled={isSubmitting}
                                >
                                    Atšaukti
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    color="primary"
                                >
                                    Išsaugoti
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

export default SubjectModalForm;
