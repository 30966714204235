import React from "react";
import { Grid } from "@material-ui/core";

import { ClientFilterProvider } from "../../hooks/ClientFilterProvider";
import { ClientListTable } from "../../components/client/ClientListTable";

export const ClientsList: React.FC = () => {
  return (
    <div>
      <ClientFilterProvider>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction="row"
          spacing={3}
        >
          <Grid item xs>
            <ClientListTable />
          </Grid>
        </Grid>
      </ClientFilterProvider>
    </div>
  );
};
