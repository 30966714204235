import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import SubjectModel from "../../core/api/manager/SubjectModel";

interface Props {
  id: string;
  name: string;
  value: string;
  error: boolean;
  helperText: string | undefined;
  onChange(e: React.ChangeEvent<any>): void;
  onBlur(e: React.FocusEvent<any>): void;
  setValue(name: string, value: string, validate: boolean): void;
}
const SubjectInput: React.FC<Props> = ({
  id,
  name,
  value,
  error,
  helperText,
  onChange,
  onBlur,
  setValue,
}) => {
  const [subjects, setSubjects] = useState<SubjectModel[]>([])

  useEffect(() => {
    SubjectModel.subjects()
      .then(s => setSubjects(s))
  }, [])

  return (
    <Autocomplete
      freeSolo
      id="subjects"
      disableClearable
      autoSelect
      onChange={(event, value) => {
        setValue(name, value, true);
      }}
      options={subjects.map((subject) => subject.subject)}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          type="text"
          name={name}
          onChange={onChange}
          value={value}
          error={error}
          helperText={helperText}
          onBlur={onBlur}
          fullWidth
          label="Laiško tema"
          margin="normal"
          variant="outlined"
          InputProps={{ ...params.InputProps, type: "search" }}
        />
      )}
    />
  );
};

export default SubjectInput;
