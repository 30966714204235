import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

interface AlertProps {
  action: () => void;
  actionName: string;
  title: string;
  body: string;
}

export default function customAlert(props: AlertProps): void {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <Dialog open={true} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
          <DialogContent>{props.body}</DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                props.action();
                onClose();
              }}
            >
              {props.actionName}
            </Button>
            <Button color="default" variant="contained" onClick={onClose}>
              Atšaukti
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
  });
}
