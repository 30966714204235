import React, { useCallback, useEffect, useState } from "react";

import MaterialTable from "material-table";
import { icons } from "../../TableIcons";
import customAlert from "../../layout/ConfirmDialog";
import { Delete, Edit } from "@material-ui/icons";
import { useNotifications } from "../../../hooks/NotificationProvider";
import SubjectModalForm from "./SubjectModalForm";
import SubjectModel from "../../../core/api/manager/SubjectModel";
import PostAddIcon from '@material-ui/icons/PostAdd';

interface ModalState {
    subjectId?: string | null,
    show: boolean
}

interface State {
    loading: boolean,
    data: SubjectModel[]
}

const SubjectsTable: React.FC = () => {
    const [modal, setModal] = useState<ModalState>({ subjectId: null, show: false })
    const notifications = useNotifications();
    const [state, setState] = useState<State>({
        loading: true,
        data: []
    })

    const load = useCallback(() => {
        setState({ loading: true, data: [] })
        SubjectModel.subjects()
            .then(s => setState({ loading: false, data: s }))
    }, [])

    useEffect(() => {
        load()
    }, [load])

    const onEditClose = () => {
        setModal({ show: false })
        load();
    }

    return (
        <div>
            <MaterialTable
                icons={icons}
                columns={[
                    {
                        field: "id",
                        title: "ID",
                        hidden: true,
                    },
                    {
                        field: "subject",
                        title: "Tema",
                    },
                ]}
                actions={[
                    {
                        icon: PostAddIcon,
                        tooltip: "Pridėti temą",
                        isFreeAction: true,
                        onClick: (event: any, rowData: any) => {
                            setModal({ show: true });
                        },
                    },
                    {
                        icon: Edit,
                        tooltip: "Redaguoti temą",
                        onClick: (event: any, rowData: any) => {
                            setModal({ show: true, subjectId: rowData.id });
                        },
                    },
                    {
                        icon: Delete,
                        tooltip: "Ištrinti temą",
                        onClick: (event: any, rowData: any) => {
                            customAlert({
                                title: "Ištrinti temą?",
                                body: "Ar tikrai ištrinti temą?",
                                action: () => {
                                    if (rowData.id) {
                                        SubjectModel.delete(rowData.id).then((r) => {
                                            load();
                                            notifications.notify({
                                                level: "success",
                                                message: "Tema sėkmingai ištrinta",
                                            });
                                        });
                                    }
                                },
                                actionName: "Ištrinti",
                            });
                        },
                    },
                ]}
                isLoading={state.loading}
                options={{
                    header: false,
                    sorting: false,
                    search: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10],
                    paging: true,
                    paginationType: "stepped",
                    headerStyle: {
                        backgroundColor: "#bdbdbd",
                    },
                    rowStyle: (rowData, index) => {
                        if (index % 2) {
                            return {};
                        }
                        return { backgroundColor: "#efefef" };
                    },
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: "Temų nėra",
                    },
                    header: {
                        actions: "",
                    },
                    toolbar: {
                        searchPlaceholder: "Ieškoti"
                    }
                }}
                data={state.data}
                title="Laiškų temos"
            />
            {modal.show && (
                <SubjectModalForm
                    close={onEditClose}
                    subjectId={modal.subjectId}
                />
            )}
        </div>
    );
};

export default SubjectsTable;
