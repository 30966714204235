import React, {useState} from "react";
import {Form, Formik, FormikProps} from "formik";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {Editor} from "@tinymce/tinymce-react";
import SubjectInput from "./SubjectInput";
import FileSelector from "./FileSelector";
import InvoiceModalView from "../invoice/InvoiceModalView";
import EntityId from "../../core/api/EntityId";
import InvoiceEmailModel from "../../core/api/invoice/InvoiceEmailModel";
import ClientEmailSelect from "../client/ClientEmailSelect";
import {useNotifications} from "../../hooks/NotificationProvider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        maxWidth: "450px",
        display: "block",
        margin: "0 auto",
      },
      textField: {
        "& > *": {
          width: "100%",
        },
      },
      submitButton: {
        marginTop: "24px",
      },
      title: {textAlign: "center"},
      successMessage: {color: "green"},
      errorMessage: {color: "red"},
      formControl: {
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
);

interface Props {
  clientId: string;
  title: string;

  close(): void;

  onSubmit(content: InvoiceEmailModel): Promise<EntityId>;

  pdfUrl(): Promise<string>;
}

const InvoiceEmailModalForm: React.FC<Props> = ({
                                                  title,
                                                  close,
                                                  onSubmit,
                                                  pdfUrl,
                                                  clientId,
                                                }) => {
  const classes = useStyles();
  const [preview, setPreview] = useState(false);
  const notifications = useNotifications();
  return (
      <div>
        <Dialog
            open={true}
            onClose={close}
            maxWidth={"lg"}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <Formik
              initialValues={InvoiceEmailModel.defaultValue}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                onSubmit(values)
                .then(() => {
                  notifications.notify({
                    level: "success",
                    message: "Laiškas išsiųstas",
                  });
                })
                .finally(() => {
                  actions.setSubmitting(false);
                  close();
                });
              }}
              validationSchema={InvoiceEmailModel.schema}
          >
            {(props: FormikProps<InvoiceEmailModel>) => {
              const {
                values,
                handleBlur,
                handleChange,
                isSubmitting,
                setFieldValue,
                touched,
                errors,
              } = props;
              return (
                  <Form>
                    <DialogContent>
                      <Grid
                          container
                          justify="space-around"
                          direction="column"
                          spacing={3}
                      >
                        <Grid item xs className={classes.textField}>
                          <SubjectInput
                              id="subject"
                              name="subject"
                              value={values.subject}
                              error={Boolean(errors.subject)}
                              helperText={errors.subject}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              setValue={setFieldValue}
                          />
                        </Grid>
                        <Grid item xs className={classes.textField}>
                          <Editor
                              tinymceScriptSrc="/tinymce/tinymce.min.js"
                              id="htmlText"
                              value={values.htmlText}
                              init={{
                                setup: function (editor) {
                                  editor.ui.registry.addToggleButton("noticeEmail", {
                                    icon: "notice",
                                    tooltip: "Svarbus laiškas",
                                    onAction: (api) => {
                                      api.setActive(!api.isActive());
                                      setFieldValue("important", api.isActive());
                                      // Add empty string to remove focus from button
                                      editor.insertContent("");
                                    },
                                  });
                                },
                                font_formats:
                                    "Arial=arial,helvetica,sans-serif;Times New Roman=times new roman,times",
                                content_style:
                                    "body { font-family: Times New Roman; }",
                                menubar: false,
                                height: "300",
                                plugins: [
                                  "advlist autolink lists link image charmap preview anchor",
                                  "table paste help wordcount",
                                ],
                                toolbar:
                                    "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | noticeEmail | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                              }}
                              onEditorChange={(value, editor) => {
                                setFieldValue("htmlText", value, true);
                              }}
                              onBlur={handleBlur}
                          />
                          {errors.htmlText && (
                              <FormHelperText error>{errors.htmlText}</FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs>
                          <ClientEmailSelect
                              id="recipients-select"
                              name="recipients-select"
                              values={values.recipients}
                              clientId={clientId}
                              errorMessage={errors.recipients}
                              touched={touched.recipients}
                              onValueChange={(emails) =>
                                  setFieldValue("recipients", emails)
                              }
                          />
                        </Grid>

                        <Grid item xs>
                          <FileSelector
                              id="attachments"
                              name="attachments"
                              value={values.attachments}
                              onChange={setFieldValue}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button type="button" onClick={() => setPreview(true)}>
                        Peržiūrėti sąskaitą
                      </Button>
                      <Button
                          type="button"
                          className="outline"
                          onClick={close}
                          disabled={isSubmitting}
                      >
                        Uždaryti
                      </Button>
                      <Button
                          type="submit"
                          disabled={isSubmitting}
                          variant="contained"
                          color="primary"
                      >
                        Siųsti
                      </Button>
                    </DialogActions>
                  </Form>
              );
            }}
          </Formik>
        </Dialog>
        {preview && (
            <InvoiceModalView close={() => setPreview(false)} pdfUrl={pdfUrl}/>
        )}
      </div>
  );
};

export default InvoiceEmailModalForm;
