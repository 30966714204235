import React from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Grid,
} from "@material-ui/core";
import InvoiceFilterModel from "../../core/api/invoice/InvoiceFilterModel";
import { TazDatePicker } from "../dates/TazDatePicker";
import ManagersSelectInput from "../classifier/ManagersSelectInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  filter: InvoiceFilterModel;
  onFilterChange: (filter: InvoiceFilterModel) => void;
}

const InvoiceFilterForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const onClear = () => {
    props.onFilterChange({
      dateFrom: "",
      dateTo: "",
      author: "",
    });
  };

  return (
    <Formik
      initialValues={props.filter}
      enableReinitialize
      onSubmit={(values, actions) => {
        props.onFilterChange(values);
        actions.setSubmitting(false);
      }}
    >
      {(props: FormikProps<InvoiceFilterModel>) => {
        const { values, handleChange, isSubmitting } = props;
        return (
          <Form>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs>
                <TazDatePicker
                  className={classes.margin}
                  id="dateFrom"
                  name="dateFrom"
                  label="Data nuo"
                  value={values.dateFrom}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TazDatePicker
                  className={classes.margin}
                  id="dateTo"
                  name="dateTo"
                  label="Data iki"
                  value={values.dateTo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <ManagersSelectInput
                  className={classes.margin}
                  name="author"
                  labelId="user-responsible-label"
                  id="author"
                  value={values.author}
                  label="Išrašęs asmuo"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <Button
                  className={classes.margin}
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Filtruoti
                </Button>
                <Button
                  className={classes.margin}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={onClear}
                  fullWidth
                >
                  Išvalyti
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InvoiceFilterForm;
