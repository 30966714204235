import { format } from "date-fns";
import qs from "qs";
import httpClient from "../config/HttpClient";
import EmailFilterModel from "../email/EmailFilterModel";
import InvoiceFilter from "../invoice/InvoiceFilterModel";
import ActiveClientCount from "./ActiveClientCount";
import ClientCount from "./ClientsCount";
import EmailStatistics from "./EmailStatistics";
import MonthlyIncome from "./MonthlyIncome";
import UserInvoiceStatistics from "./UserInvoiceStatistics";

const STATISTICS_URL = "/statistics"

export async function userInvoiceStatistics(filter?: InvoiceFilter) {
  return await httpClient
    .get<UserInvoiceStatistics[]>(STATISTICS_URL + "/users", {
      params: filter,
      paramsSerializer: params => {
        return qs.stringify(params, {
          serializeDate: (date: Date) => format(date, "yyyy-MM-dd")
        });
      }
    })
    .then((resp) => resp.data);

};

export async function clientCounts() {
  return await httpClient
    .get<ClientCount[]>(STATISTICS_URL + "/clients/counts")
    .then((resp) => resp.data);

};

export async function activeClientStats() {
  const result = await httpClient.get<ActiveClientCount[]>(STATISTICS_URL + "/clients/active-counts")
  return result.data
};

export async function monthlyIncome() {
  const result = await httpClient.get<MonthlyIncome[]>(STATISTICS_URL + "/monthly-income")
  return result.data
};

export async function emailStatistics(filter: EmailFilterModel) {
  const result = await httpClient.get<EmailStatistics[]>(STATISTICS_URL + "/email-statistics",
   {
     params: filter,
     paramsSerializer: params => {
      return qs.stringify(params, {
        serializeDate: (date: Date) => format(date, "yyyy-MM-dd")
      });
    }
  })
  return result.data
};
