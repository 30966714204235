import * as Yup from "yup";

import InvoiceFilter from "./InvoiceFilterModel";
import PageRequest from "../PageRequest";
import PageResponse from "../PageResponse";
import EntityId from "../EntityId";
import { addYears, format } from "date-fns";
import qs from "qs";
import InvoiceEmailModel from "./InvoiceEmailModel";
import httpClient from "../config/HttpClient";

const INVOICE_URL = "/invoices";

export class InvoiceCreateModel {
  clientId!: string;
  date!: string;
  price!: number | null;
  periodFrom!: string;
  periodTo!: string;
  jobTitle!: string;
  discount!: number | null;

  static defaultValue = (clientId: string): InvoiceModel => {
    const currentDate = new Date();
    return {
      invoiceNo: "",
      clientId: clientId,
      date: format(currentDate, "yyyy-MM-dd"),
      periodFrom: format(currentDate, "yyyy-MM-dd"),
      periodTo: format(addYears(currentDate, 1), "yyyy-MM-dd"),
      price: null,
      discount: null,
      jobTitle: "",
    };
  };

  static withDates = (
    clientId: string,
    periodFrom: string,
    periodTo: string
  ): InvoiceModel => {
    const currentDate = new Date();
    return {
      invoiceNo: "",
      clientId: clientId,
      date: format(currentDate, "yyyy-MM-dd"),
      periodFrom: periodFrom,
      periodTo: periodTo,
      price: null,
      discount: null,
      jobTitle: "",
    };
  };

  static createSchema = Yup.object().shape({
    clientId: Yup.string().required("Vartotojas yra privalomas"),
    date: Yup.date().required("Data yra privaloma"),
    periodFrom: Yup.date().required("Prenumeratos pradžios data yra privaloma"),
    periodTo: Yup.date().required("Prenumeratos pabaigos data yra privaloma"),
    price: Yup.number()
      .optional()
      .typeError("Kaina turi būti validus skaičius")
      .required("Kaina yra privaloma"),
    discount: Yup.number()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .nullable()
      .test(
        "valid-discount",
        "Nuolaida negali būti didesnė už kainą",
        function (value) {
          if (value) {
            return this.parent.price > value;
          }
          return true;
        }
      ),
  });

  static pdfPreview = (model: InvoiceCreateModel) => {
    return httpClient
      .post(INVOICE_URL + "/preview-pdf", model, {
        responseType: "blob",
      })
      .then((resp) => URL.createObjectURL(resp.data));
  };
}
class InvoiceModel extends InvoiceCreateModel {
  invoiceNo!: string;
  addressee?: string;
  companyTitle?: string;
  companyCode?: string;
  companyVatCode?: string;
  companyAddress?: string;
  companyPhone?: string;
  companyEmail?: string;
  email?: string;
  clientId!: string;
  date!: string;
  price!: number | null;
  periodFrom!: string;
  periodTo!: string;
  lastSentDate?: string;
  pvm?: number | null;
  discount!: number | null;
  author?: string;

  static defaultValue = (invoiceNo: string): InvoiceModel => {
    const currentDate = new Date();
    return {
      invoiceNo: invoiceNo,
      clientId: "",
      date: format(currentDate, "yyyy-MM-dd"),
      periodFrom: format(currentDate, "yyyy-MM-dd"),
      periodTo: format(addYears(currentDate, 1), "yyyy-MM-dd"),
      price: null,
      discount: null,
      jobTitle: "",
      addressee: "",
      companyTitle: "",
      companyCode: "",
      companyVatCode: "",
      companyAddress: "",
      companyPhone: "",
      companyEmail: "",
    };
  };

  static schema = Yup.object().shape({
    clientId: Yup.string().required("Vartotojas yra privalomas"),
    date: Yup.date().required("Data yra privaloma"),
    periodFrom: Yup.date().required("Prenumeratos pradžios data yra privaloma"),
    periodTo: Yup.date().required("Prenumeratos pabaigos data yra privaloma"),
    price: Yup.number()
      .optional()
      .typeError("Kaina turi būti validus skaičius")
      .required("Kaina yra privaloma"),
    discount: Yup.number()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .nullable()
      .test(
        "valid-discount",
        "Nuolaida negali būti didesnė už kainą",
        function (value) {
          if (value) {
            return this.parent.price > value;
          }
          return true;
        }
      ),
    companyTitle: Yup.string().required("Įmonės pavadinimas yra privalomas"),
    companyCode: Yup.string().required("Įmonės kodas yra privalomas"),
    companyVatCode: Yup.string().required("Įmonės PVM kodas yra privalomas"),
    companyAddress: Yup.string().required("Įmonės adresas yra privalomas"),
    companyPhone: Yup.string().required("Telefono numeris yra privalomas"),
    companyEmail: Yup.string().required("El. pašto adresas yra privalomas"),
  });

  static findOne = (id: string) => {
    return httpClient
      .get<InvoiceModel>(INVOICE_URL + "/" + id)
      .then((resp) => resp.data);
  };

  static pdfUrl = (id: string) => {
    return "/api" + INVOICE_URL + "/" + id + "/pdf";
  };

  static invoicePdfPreview = (model: InvoiceModel) => {
    return httpClient
      .post(INVOICE_URL + "/" + model.invoiceNo + "/preview-pdf", model, {
        responseType: "blob",
      })
      .then((resp) => URL.createObjectURL(resp.data));
  };

  static pdf = async (id: string | undefined) => {
    return httpClient
      .get(INVOICE_URL + "/" + id + "/pdf", {
        responseType: "blob",
      })
      .then((resp) => URL.createObjectURL(resp.data));
  };

  static create = async (model: InvoiceCreateModel) => {
    return await httpClient
      .post<EntityId>(INVOICE_URL, model)
      .then((resp) => resp.data);
  };

  static update = async (model: InvoiceModel) => {
    return await httpClient
      .put<EntityId>(INVOICE_URL + "/" + model.invoiceNo, model)
      .then((resp) => resp.data);
  };

  static delete = (id: string) => {
    return httpClient.delete(INVOICE_URL + "/" + id).then((resp) => resp.data);
  };

  static listAll = async (page: PageRequest, filter?: InvoiceFilter) => {
    return httpClient
      .get<PageResponse<InvoiceModel>>(INVOICE_URL, {
        params: {
          ...filter,
          ...page,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            serializeDate: (date: Date) => format(date, "yyyy-MM-dd"),
          });
        },
      })
      .then((resp) => resp.data as PageResponse<InvoiceModel>);
  };

  static listClient = async (page: PageRequest, clientId: string) => {
    return httpClient
      .get<PageResponse<InvoiceModel>>(INVOICE_URL + "/clients/" + clientId, {
        params: {
          ...page,
        },
      })
      .then((resp) => resp.data as PageResponse<InvoiceModel>);
  };

  static send = async (content: InvoiceEmailModel, invoiceNo: string) => {
    const resp = await httpClient.post(
      INVOICE_URL + "/" + invoiceNo + "/send",
      content
    );
    return resp.data.content as EntityId;
  };
}
export default InvoiceModel;
