import {
  createStyles,
  Drawer,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ReminderTable from "../components/reminder/ReminderTable";
import ReminderFilterForm from "../components/reminder/ReminderFilterForm";
import ReminderModel, {
  ReminderFilter,
} from "../core/api/reminder/ReminderModel";
import { parsePagination } from "../core/PaginationUtils";
import Pagination from "../core/api/PageRequest";
import { useLocation } from "react-router-dom";
import { GoToPagination } from "../components/GoToPagination";
import { PersistentSearchHeader } from "../components/PersistentSearchHeader";
import EditIcon from "@material-ui/icons/Edit";
import ReminderModalForm from "../components/reminder/ReminderModalForm";
import FaceIcon from "@material-ui/icons/Face";
import ClientStateModalForm from "../components/client/ClientStateModalForm";

const drawerWidth = 330;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    fab: {
      position: "fixed",
      top: theme.spacing(12),
      right: theme.spacing(-4),
      transform: "rotate(-90deg)",
    },
    rootContainer: {
      maxWidth: "90vw",
    },
  })
);

const parseFilter = (search: string): ReminderFilter => {
  const params = new URLSearchParams(search);
  return {
    tableFilter: params.get("tableFilter") || "",
    companyFilter: params.get("companyFilter") || "",
    dateFrom: params.get("dateFrom") || "",
    dateTo: params.get("dateTo") || "",
    clientState: params.get("clientState") || "",
  };
};

export const Home: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [modifyReminders, setModifyReminders] = useState(false);
  const [modifyClients, setModifyClients] = useState(false);
  const [reminders, setReminders] = useState<ReminderModel[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pagination, setPagination] = useState<Pagination>(
    parsePagination(location.search)
  );
  const [filter, setFilter] = useState<ReminderFilter>(
    parseFilter(location.search)
  );

  const fetchReminders = useCallback(async () => {
    const pagination = parsePagination(location.search);
    setPagination(pagination);
    const filter = parseFilter(location.search);
    setFilter(filter);
    setIsLoading(true);
    const page = await ReminderModel.remindersList(pagination, filter);
    setTotalCount(page.totalElements);
    setReminders(page.content);
    setIsLoading(false);
  }, [location.search]);

  useEffect(() => {
    fetchReminders();
  }, [fetchReminders]);

  function openClose() {
    setFilterOpen(!filterOpen);
  }

  return (
    <div>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: filterOpen,
        })}
      >
        <Fab
          color="secondary"
          variant="extended"
          onClick={openClose}
          className={classes.fab}
          size="small"
        >
          Filtruoti
        </Fab>
        <Grid container spacing={3} justify="center" direction="column">
          <Grid item xs>
            <ReminderTable
              customHeader={
                <PersistentSearchHeader
                  title="Priminimai"
                  actions={
                    <span>
                      <IconButton
                        color="primary"
                        aria-label="Redaguoti priminimų sąrašą"
                        onClick={() => setModifyReminders(true)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        aria-label="Redaguoti priminimų sąrašą"
                        onClick={() => setModifyClients(true)}
                      >
                        <FaceIcon />
                      </IconButton>
                    </span>
                  }
                />
              }
              reminders={reminders}
              isLoading={isLoading}
              pagination={
                <GoToPagination
                  count={totalCount}
                  rowsPerPageOptions={[10]}
                  pageSize={pagination.size}
                />
              }
              refresh={fetchReminders}
              pageSize={pagination.size}
            />
          </Grid>
        </Grid>
      </div>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={filterOpen}
        onClose={openClose}
        variant="persistent"
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={openClose}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Grid item>
            <ReminderFilterForm filter={filter} />
          </Grid>
        </Grid>
      </Drawer>
      <div className={classes.drawerHeader} />
      {modifyReminders && (
        <ReminderModalForm
          value={ReminderModel.defaultValue}
          save={(values) => ReminderModel.updateReminderList(values, filter)}
          close={() => {
            setModifyReminders(false);
            fetchReminders();
          }}
        />
      )}

      {modifyClients && (
        <ClientStateModalForm
          save={(values) =>
            ReminderModel.updateReminderClientStates(values, filter)
          }
          close={() => {
            setModifyClients(false);
            fetchReminders();
          }}
        />
      )}
    </div>
  );
};
