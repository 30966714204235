import * as Yup from "yup";
import httpClient from "../config/HttpClient";
import { MANAGERS_DETAILS, MANAGERS_URL } from "../routes";

class ManagerDetails {
  email!: string;
  firstName!: string;
  lastName!: string;

}

class ManagersModel extends ManagerDetails {
  id?: string;
  username!: string;
  userType!: string;
  active!: boolean

  static defaultValue: ManagersModel = {
    username: "",
    email: "",
    userType: "",
    firstName: "",
    lastName: "",
    active: true
  };

  static updateDetails = async (model: ManagerDetails) => {
    return await httpClient.put<Partial<ManagersModel>>(MANAGERS_DETAILS, model)
      .then((resp) => resp.data);
  }

  static schema = Yup.object().shape({
    email: Yup.string()
      .email("Neteisingas el. pašto adresas")
      .required("El. pašto adresas yra privalomas"),
    username: Yup.string().required("Vartotojo vardas yra privalomas!"),
    userType: Yup.string().required("Vartotojo tipas yra privalomas!"),
    firstName: Yup.string().required("Vardas yra privalomas!"),
    lastName: Yup.string().required("Pavardė yra privaloma!"),
    active: Yup.string().required("Vartotojo statusas yra privalomas!")
  });

  static updateOrCreate = async (model: ManagersModel) => {
    if (model.id) {
      return await httpClient
        .put<ManagersModel>(MANAGERS_URL + "/" + model.id, model)
        .then((resp) => resp.data);
    } else {
      return await httpClient
        .post<ManagersModel>(MANAGERS_URL, model)
        .then((resp) => resp.data);
    }
  };


  static manager = async () => {
    return await httpClient
      .get<ManagersModel>(MANAGERS_URL + "/me")
      .then((resp) => resp.data);
  };

  static delete = async (model: ManagersModel) => {
    return await httpClient
      .delete(MANAGERS_URL + "/" + model.id)
      .then((resp) => resp.data);
  };

  static resetPassword = async (id: string) => {
    return await httpClient
      .post(MANAGERS_URL + "/remember/" + id)
      .then((resp) => resp.data);
  };

  static managersList = () => httpClient
    .get<ManagersModel[]>(MANAGERS_URL)
    .then((resp) => resp.data as ManagersModel[]);
  ;
}
export default ManagersModel;
