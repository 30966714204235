import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { SideBarItems } from "./SidebarItems";
import { SideBarNavItem } from "./SidebarNavItem";
import { useAuth } from "../../hooks/AuthProvider";
import Logo from "../../assets/logo_alpha.png";

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: "flex",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      "text-align": "center",
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    activeButton: {
      background: "red",
      color: "white",
    },
    img: {
      "max-width": "80%",
    },
  })
);

export const Sidebar: React.FC = () => {
  const classes = useStyles();
  const auth = useAuth();
  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <img className={classes.img} src={Logo} alt="Logo" />
        </div>
        <Divider />
        <List>
          {SideBarItems.filter((item) => !item.adminOnly).map((item) => (
            <SideBarNavItem key={item.text} {...item} />
          ))}
          <Divider />
          {auth.isAdmin && (
            <div>
              {SideBarItems.filter((item) => item.adminOnly).map((item) => (
                <SideBarNavItem key={item.text} {...item} />
              ))}
            </div>
          )}
        </List>
      </Drawer>
    </div>
  );
};
