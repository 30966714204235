import { Button, createStyles, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import ReminderModel, { ReminderView } from "../../core/api/reminder/ReminderModel";
import ReminderModalForm from "./ReminderModalForm";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reminderText: {
      maxHeight: "250px",
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%"
    },
    buttons: {
      margin: theme.spacing(2)
    }

  })
);


export interface Props {
  clientId: string;
}

const ReminderViewBox: React.FC<Props> = ({ clientId }) => {
  const [reminder, setReminder] = useState<ReminderView | null>(null);
  const [edit, setEdit] = useState(false);

  const deleteReminder = () => {
    if (reminder) {
      ReminderModel.delete(reminder.clientId)
        .then(r => setReminder(null));
    }
  };

  const loadReminder = useCallback(() => {
    if (clientId) {
      ReminderModel.reminder(clientId)
        .then((r) => setReminder(r))
        .catch((err) => {

        });
    }
  }, [clientId])

  useEffect(() => {
    loadReminder()
  }, [loadReminder]);

  const editReminder = () => {
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
    loadReminder();
  };

  const classes = useStyles();
  return (
    reminder && (<div>
      <Grid
        component={Paper}
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs>
          <Typography align="center" variant="h6">Priminimas</Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.reminderText}>
            {reminder.comment.split("\n").map((i, key) => {
              return <div key={key}>{i}</div>;
            })}
          </Typography>
        </Grid>
        <Grid item xs>
          <Button variant="contained" color="primary" onClick={editReminder} className={classes.buttons}>
            Redaguoti
            </Button>
          <Button variant="contained" color="secondary" onClick={deleteReminder} className={classes.buttons}>
            Ištrinti
            </Button>
        </Grid>
      </Grid>
      {edit && reminder && <ReminderModalForm
        value={{ date: reminder.date, comment: reminder.comment }}
        save={(value) => ReminderModel.updateOrCreate(reminder.clientId, value)}
        close={closeEdit}
      />}
    </div>)
  );
};

export default ReminderViewBox;
