import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import MaterialTable, { Action } from "material-table";
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import ClientModel from "../../core/api/clients/ClientModel";
import { useAuth } from "../../hooks/AuthProvider";
import { useNotifications } from "../../hooks/NotificationProvider";
import customAlert from "../layout/ConfirmDialog";
import { icons } from "../TableIcons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import qs from "qs";

interface Props {
  clients: ClientModel[];
  pagination: ReactNode;
  tableFilter?: ReactNode;
  customHeader?: ReactNode;
  refresh(): void;
  isLoading: boolean;
  pageSize: number;
  toolbar: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fixedWidth: {
      float: "right",
      display: "inline-block",
      maxWidth: "250px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      padding: 0,
      margin: 0,
      verticalAlign: "bottom",
    },
    fullRow: {
      display: "block",
    },
    boldRow: {
      display: "block",
      fontWeight: "bold",
    },
    right: {
      display: "inline-block",
      float: "right",
      padding: 0,
      margin: 0,
      verticalAlign: "bottom",
    },
    bold: {
      fontWeight: "bold",
      display: "inline-block",
      padding: 0,
      margin: 0,
      verticalAlign: "bottom",
    },
    fullWidth: {
      width: "100$",
    },
    rootContainer: {
      textOverflow: "ellipsis",
      fontSize: "14px",
    },
  })
);

const ClientTable: React.FC<Props> = ({
  clients,
  pagination,
  refresh,
  isLoading,
  pageSize,
  toolbar,
  customHeader,
}) => {
  const auth = useAuth();
  const notifications = useNotifications();
  const history = useHistory();
  const myClients =
    new URLSearchParams(history.location.search).get("myClients") === "true";
  const states = new Map([
    ["PAST", "Buvęs"],
    ["ACTIVE", "Aktyvus"],
    ["DEAD", "Neaktyvus"],
    ["COMATOSE", "Tarpinis"],
    ["HIDDEN", "Paslėptas"],
    ["SPECIAL", "Ypatingas"],
    ["OTHER", "Kitas"],
  ]);

  const actions: Action<any>[] = [
    {
      icon: AssignmentIndIcon,
      tooltip: "Peržiūrėti klientą",
      onClick: (event: any, rowData: any) => {
        history.push({
          pathname: `/client/${rowData.id}`,
          search: qs.stringify({ myClients }),
        });
      },
    },
  ];

  if (auth.isAdmin) {
    actions.push({
      icon: Delete,
      tooltip: "Ištrinti klientą",
      onClick: (event: any, rowData: any) => {
        customAlert({
          title: "Ištrinti klientą?",
          body: `Ar tikrai ištrinti ${rowData.companyTitle}?`,
          action: () => {
            if (rowData.id) {
              ClientModel.delete(rowData.id)
                .then((r) => {
                  refresh();
                  notifications.notify({
                    level: "success",
                    message: "Klientas sėkmingai ištrintas",
                  });
                })
                .catch((err) => {
                  notifications.notify({
                    level: "error",
                    message: err.response.data.description,
                  });
                });
            }
          },
          actionName: "Ištrinti",
        });
      },
    });
  }
  const classes = useStyles();
  return (
    <div>
      <MaterialTable
        icons={icons}
        columns={[
          {
            title: "ID",
            field: "id",
            cellStyle: { width: "50px" },
          },
          {
            title: "Įmonės informacija",
            render: (rowData) => {
              return (
                <span className={classes.rootContainer}>
                  <span className={classes.fullRow}>
                    <span className={classes.bold}>Įmonė:&nbsp;</span>
                    <span className={classes.fixedWidth}>
                      {rowData.companyTitle}
                    </span>
                  </span>
                  <span className={classes.fullRow}>
                    <span className={classes.bold}>Įmonės kodas:&nbsp;</span>
                    <span className={classes.right}>{rowData.companyCode}</span>
                  </span>
                  <span className={classes.fullRow}>
                    <span className={classes.bold}>PVM kodas:&nbsp;</span>
                    <span className={classes.right}>
                      {rowData.companyVatCode}
                    </span>
                  </span>
                </span>
              );
            },
          },
          {
            title: "Kontaktinė informacija",
            render: (rowData) => {
              return (
                <span className={classes.rootContainer}>
                  <span className={classes.fullRow}>
                    <span className={classes.bold}>Adresas:&nbsp;</span>
                    <p className={classes.fixedWidth}>
                      {rowData.companyAddress}
                    </p>
                  </span>
                  <span className={classes.fullRow}>
                    <span className={classes.bold}>Tel. nr.:&nbsp;</span>
                    <span className={classes.right}>
                      {rowData.companyPhone}
                    </span>
                  </span>
                  <span className={classes.fullRow}>
                    <span className={classes.bold}>El. paštas:&nbsp;</span>
                    <span className={classes.right}>{rowData.mainEmail}</span>
                  </span>
                </span>
              );
            },
          },
          {
            title: "Kliento būsena",
            render: (rowData) => {
              return (
                <span className={classes.rootContainer}>
                  <span className={classes.fullRow}>
                    <span className={classes.bold}>Prenumerata nuo:&nbsp;</span>
                    <span className={classes.right}>{rowData.validFrom}</span>
                  </span>
                  <span className={classes.fullRow}>
                    <span className={classes.bold}>Prenumerata iki:&nbsp;</span>
                    <span className={classes.right}>{rowData.validTo}</span>
                  </span>
                  <span className={classes.fullRow}>
                    <span className={classes.bold}>Būsena:&nbsp;</span>
                    <span className={classes.right}>
                      {states.get(rowData.clientState)}
                    </span>
                  </span>
                </span>
              );
            },
          },
          {
            title: "Atsakingas asmuo",
            render: (rowData) => {
              return (
                <span className={classes.rootContainer}>
                  <span className={classes.boldRow}>Atsakingas asmuo</span>
                  <span className={classes.fullRow}>
                    {rowData.responsibleUserName}
                  </span>
                </span>
              );
            },
          },
        ]}
        options={{
          search: false,
          padding: "dense",
          toolbar: toolbar,
          header: false,
          sorting: false,
          actionsColumnIndex: -1,
          pageSize: pageSize,
          maxBodyHeight: "70vh",
          headerStyle: {
            backgroundColor: "#bdbdbd",
          },
          rowStyle: (rowData, index) => {
            if (index % 2) {
              return {};
            }
            return { backgroundColor: "#efefef" };
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Klientų nėra",
          },
        }}
        isLoading={isLoading}
        actions={actions}
        data={clients}
        components={{
          Toolbar: () => <>{customHeader}</>,
          Pagination: () => <>{pagination}</>,
        }}
      />
    </div>
  );
};

export default ClientTable;
