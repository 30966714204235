import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { MaterialTableProps } from "material-table";
import { TazDatePicker } from "../dates/TazDatePicker";
import InvoiceModel from "../../core/api/invoice/InvoiceModel";
import InvoiceModalView from "./InvoiceModalView";
import { useNotifications } from "../../hooks/NotificationProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      "& > *": {
        width: "100%",
      },
    },
    submitButton: {
      marginTop: "24px",
    },
    title: { textAlign: "center" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface PreviewableInvoice extends InvoiceModel {
  preview?: boolean;
}

interface Props {
  invoiceId: string;
  close(): void;
  onSuccess(id: string): void;
  tableRef: React.RefObject<MaterialTableProps<any>>;
}

interface PreviewState {
  preview: boolean;
  pdfUrl?(): Promise<string>;
}

const InvoiceEditModalForm: React.FC<Props> = ({
  invoiceId,
  close,
  tableRef,
  onSuccess,
}) => {
  const classes = useStyles();
  const notifications = useNotifications();
  const [value, setValue] = useState<PreviewableInvoice>(
    InvoiceModel.defaultValue(invoiceId)
  );
  const [preview, setPreview] = useState<PreviewState>({ preview: false });

  useEffect(() => {
    InvoiceModel.findOne(invoiceId).then((r) => setValue(r));
  }, [invoiceId]);

  return (
    <div>
      <Dialog
        open={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">Sąskaitos forma</DialogTitle>
        <Formik
          initialValues={value}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            if (values.preview) {
              setValue(values);
              setPreview({
                preview: true,
                pdfUrl: () => InvoiceModel.invoicePdfPreview(values),
              });
              actions.setSubmitting(false);
            } else {
              InvoiceModel.update(values)
                .then((body) => {
                  close();
                  notifications.notify({
                    level: "success",
                    message: "Sąskaita išsaugota sėkmingai",
                  });
                  onSuccess(body.id);
                })
                .then(() => {
                  const ref = tableRef.current as any;
                  ref.onQueryChange();
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }
          }}
          validationSchema={InvoiceModel.schema}
        >
          {(props: FormikProps<InvoiceModel>) => {
            const {
              values,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <DialogContent>
                  <Grid
                    container
                    justify="space-around"
                    direction="row"
                    spacing={2}
                  >
                    <Grid item xs={6} className={classes.textField}>
                      <TextField
                        error={Boolean(errors.companyTitle)}
                        id="companyTitle"
                        type="text"
                        name="companyTitle"
                        label="Įmonės pavadinimas"
                        fullWidth
                        onChange={handleChange}
                        helperText={errors.companyTitle}
                        value={values.companyTitle}
                        onBlur={handleBlur}
                      />
                      <TextField
                        error={Boolean(errors.companyCode)}
                        id="companyCode"
                        type="text"
                        name="companyCode"
                        label="Įmonės kodas"
                        onChange={handleChange}
                        fullWidth
                        helperText={errors.companyCode}
                        value={values.companyCode}
                        onBlur={handleBlur}
                      />
                      <TextField
                        error={Boolean(errors.companyVatCode)}
                        id="companyVatCode"
                        type="text"
                        name="companyVatCode"
                        label="Įmonės PVM mokėtojo kodas"
                        fullWidth
                        onChange={handleChange}
                        helperText={errors.companyVatCode}
                        value={values.companyVatCode}
                        onBlur={handleBlur}
                      />
                      <TextField
                        error={Boolean(errors.companyAddress)}
                        id="companyAddress"
                        type="text"
                        name="companyAddress"
                        label="Įmonės adresas"
                        onChange={handleChange}
                        fullWidth
                        helperText={errors.companyAddress}
                        value={values.companyAddress}
                        onBlur={handleBlur}
                      />
                      <TextField
                        error={Boolean(errors.companyEmail)}
                        id="companyEmail"
                        type="text"
                        name="companyEmail"
                        label="El. paštas"
                        onChange={handleChange}
                        helperText={errors.companyEmail}
                        fullWidth
                        value={values.companyEmail}
                        onBlur={handleBlur}
                      />
                      <TextField
                        error={Boolean(errors.companyPhone)}
                        id="companyPhone"
                        type="text"
                        name="companyPhone"
                        label="Telefono numeris"
                        onChange={handleChange}
                        helperText={errors.companyPhone}
                        fullWidth
                        value={values.companyPhone}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.textField}>
                      <TazDatePicker
                        id="date"
                        name="date"
                        label="Data"
                        onChange={handleChange}
                        error={Boolean(errors.date)}
                        helperText={errors.date}
                        value={values.date}
                        onBlur={handleBlur}
                      />
                      <TazDatePicker
                        id="periodFrom"
                        name="periodFrom"
                        label="Prenumerata nuo"
                        error={Boolean(errors.periodFrom)}
                        helperText={errors.periodFrom}
                        onChange={handleChange}
                        value={values.periodFrom}
                        onBlur={handleBlur}
                      />
                      <TazDatePicker
                        id="periodTo"
                        name="periodTo"
                        label="Prenumerata iki"
                        error={Boolean(errors.periodTo)}
                        helperText={errors.periodTo}
                        onChange={handleChange}
                        value={values.periodTo}
                        onBlur={handleBlur}
                      />
                      <NumberFormat
                        customInput={TextField}
                        name="price"
                        id="price"
                        label="Kaina"
                        value={values.price ?? ""}
                        fullWidth
                        allowedDecimalSeparators={[".", ","]}
                        helperText={errors.price}
                        error={Boolean(errors.price)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <NumberFormat
                        customInput={TextField}
                        name="discount"
                        id="discount"
                        label="Nuolaida"
                        value={values.discount ?? ""}
                        fullWidth
                        allowedDecimalSeparators={[".", ","]}
                        helperText={errors.discount}
                        error={Boolean(errors.discount)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        name="jobTitle"
                        id="jobTitle"
                        label="Išrašęs asmuo:"
                        value={values.jobTitle}
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      setFieldValue("preview", true);
                    }}
                  >
                    Peržiūrėti sąskaitą
                  </Button>
                  <Button
                    type="button"
                    className="outline"
                    onClick={close}
                    disabled={isSubmitting}
                  >
                    Atšaukti
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => setFieldValue("preview", false)}
                    variant="contained"
                    color="primary"
                  >
                    Išsaugoti
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      {preview && preview.pdfUrl && (
        <InvoiceModalView
          close={() => setPreview({ preview: false })}
          pdfUrl={preview.pdfUrl}
        />
      )}
    </div>
  );
};

export default InvoiceEditModalForm;
