import React, { ReactNode, useRef, useState } from "react";

import MaterialTable, { MaterialTableProps } from "material-table";
import { icons } from "../TableIcons";
import ReminderModel, {
  ReminderView,
} from "../../core/api/reminder/ReminderModel";
import customAlert from "../layout/ConfirmDialog";
import { Delete, Edit } from "@material-ui/icons";
import { useNotifications } from "../../hooks/NotificationProvider";
import { useHistory } from "react-router-dom";
import PageviewIcon from "@material-ui/icons/Pageview";
import { format, parseISO } from "date-fns";
import qs from "qs";
import ReminderModalForm from "./ReminderModalForm";

interface Props {
  reminders: ReminderModel[];
  pagination: ReactNode;
  customHeader?: ReactNode;
  refresh(): void;
  isLoading: boolean;
  pageSize: number;
}

const ReminderTable: React.FC<Props> = ({
  reminders,
  pagination,
  customHeader,
  refresh,
  isLoading,
  pageSize,
}) => {
  const tableRef = useRef<MaterialTableProps<any>>(null);
  const [reminder, setReminder] = useState<ReminderView | null>(null);
  const notifications = useNotifications();
  const history = useHistory();
  return (
    <div>
      <MaterialTable
        icons={icons}
        tableRef={tableRef}
        columns={[
          {
            field: "date",
            title: "Data",
            render: (rowData) => {
              return (
                <span>
                  {format(parseISO(String(rowData.date)), "yyyy-MM-dd HH:mm")}
                </span>
              );
            },
          },
          {
            title: "Klientas",
            field: "companyTitle",
          },
          {
            title: "Kliento ID",
            field: "clientId",
          },
          {
            title: "Kliento statusas",
            field: "clientState",
            lookup: {
              PAST: "Buvęs",
              ACTIVE: "Aktyvus",
              DEAD: "Neaktyvus",
              COMATOSE: "Tarpinis",
              HIDDEN: "Paslėptas",
              SPECIAL: "Ypatingas",
              OTHER: "Kitas",
            },
          },
          {
            title: "Komentaras",
            field: "comment",
          },
        ]}
        actions={[
          {
            icon: PageviewIcon,
            tooltip: "Peržiūrėti",
            onClick: (event: any, rowData: any) => {
              if (rowData && rowData.clientId) {
                history.replace({
                  pathname: `/client/${rowData.clientId}`,
                  search: qs.stringify({
                    myClients: true,
                  }),
                });
              }
            },
          },
          {
            icon: Edit,
            tooltip: "Redaguoti priminimą",
            onClick: (event: any, rowData: any) => {
              setReminder(rowData);
            },
          },
          {
            icon: Delete,
            tooltip: "Ištrinti priminimą",
            onClick: (event: any, rowData: any) => {
              customAlert({
                title: "Ištrinti priminimą?",
                body: "Ar tikrai ištrinti priminimą?",
                action: () => {
                  ReminderModel.delete(rowData.clientId).then((r) => {
                    refresh();
                    notifications.notify({
                      level: "success",
                      message: "Priminimas sėkmingai ištrintas",
                    });
                  });
                },
                actionName: "Ištrinti",
              });
            },
          },
        ]}
        options={{
          pageSize: pageSize,
          maxBodyHeight: "70vh",
          sorting: false,
          search: true,
          actionsColumnIndex: -1,
          paging: true,
          paginationType: "stepped",
          headerStyle: {
            backgroundColor: "#bdbdbd",
          },
          rowStyle: (rowData, index) => {
            if (index % 2) {
              return {};
            }
            return { backgroundColor: "#efefef" };
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Priminimų nėra",
          },
          header: {
            actions: "",
          },
        }}
        isLoading={isLoading}
        data={reminders}
        title="Priminimai"
        components={{
          Toolbar: () => <>{customHeader}</>,
          Pagination: () => <>{pagination}</>,
        }}
      />
      {reminder && (
        <ReminderModalForm
          value={reminder}
          save={async (values) => {
            const resp = await ReminderModel.updateOrCreate(
              reminder.clientId,
              values
            );
            refresh();
            return resp;
          }}
          close={() => setReminder(null)}
        />
      )}
    </div>
  );
};

export default ReminderTable;
