import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import ActiveClientCount from "../../core/api/statistics/ActiveClientCount";
import { activeClientStats } from "../../core/api/statistics/StatisticsService";
import { icons } from "../TableIcons";

const ActiveClientCountsTable: React.FC = (props) => {
  const [data, setData] = useState<ActiveClientCount[]>([]);
  useEffect(() => {
    activeClientStats().then((r) => setData(r));
  }, []);

  return (
    <MaterialTable
      icons={icons}
      columns={[
        {
          title: "Data",
          field: "date",
          type: "date",
          cellStyle: {
            minWidth: "120px",
          },
        },
        {
          title: "Klientų skaičius",
          field: "count",
        },
      ]}
      options={{
        padding: "dense",
        sorting: false,
        search: false,
        paging: false,
        maxBodyHeight: "460px",
        headerStyle: {
          backgroundColor: "#bdbdbd",
        },
        rowStyle: (rowData, index) => {
          if (index % 2) {
            return {};
          }
          return { backgroundColor: "#efefef" };
        },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: "Sąskaitų statistikos nėra",
        },
      }}
      data={data}
      title="Pasibaigiančios prenumerators"
    />
  );
};

export default ActiveClientCountsTable;
