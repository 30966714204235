import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import EmailFilterModel from "../../core/api/email/EmailFilterModel";
import EmailStatistics from "../../core/api/statistics/EmailStatistics";
import { emailStatistics } from "../../core/api/statistics/StatisticsService";
import { icons } from "../TableIcons";

export interface TableProps {
  filter: EmailFilterModel;
}

function sum(x: number | undefined, y: number | undefined) {
  if (x && y) {
    return +x + y;
  }
  return x || y;
}

function totalAcumulator(
  acumulator: EmailStatistics,
  initialValue: EmailStatistics
): EmailStatistics {
  return {
    createdByName: "Iš viso",
    count: Number(sum(initialValue.count, acumulator.count)?.toFixed(2)),
  };
}

const EmailStatisticsTable: React.FC<TableProps> = (props) => {
  const [data, setData] = useState<EmailStatistics[]>([]);
  useEffect(() => {
    emailStatistics(props.filter).then((r) => {
      r.push({});
      r.push(r.reduce(totalAcumulator));
      setData(r);
    });
  }, [props.filter]);

  return (
    <MaterialTable
      icons={icons}
      columns={[
        { title: "", field: "createdByName" },
        { title: "Laiškų skaičius", field: "count" },
      ]}
      options={{
        padding: "dense",
        sorting: false,
        search: false,
        paging: false,
        headerStyle: {
          backgroundColor: "#bdbdbd",
        },
        rowStyle: (rowData, index) => {
          if (index % 2) {
            return {};
          }
          return { backgroundColor: "#efefef" };
        },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: "Laiškų statistikos nėra",
        },
      }}
      data={data}
      title={`Statistika nuo
       ${props.filter?.dateFrom} 
       iki ${props.filter?.dateTo}`}
    />
  );
};

export default EmailStatisticsTable;
