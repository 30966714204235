import {
  createStyles,
  Divider,
  FormHelperText,
  IconButton,
  InputBase,
  makeStyles,
  TablePagination,
  Theme,
} from "@material-ui/core";
import * as React from "react";
import DirectionsIcon from "@material-ui/icons/Directions";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    input: {
      marginLeft: theme.spacing(1),
      width: 150,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 15,
      margin: 4,
    },
  })
);

interface Props {
  count: number;
  rowsPerPageOptions?: Array<number | { value: number; label: string }>;
  pageSize: number;
}

export const GoToPagination: React.FC<Props> = ({
  count,
  rowsPerPageOptions,
  pageSize,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);
  const [inputPage, setInputPage] = useState<number | null>(null);
  const [size, setSize] = useState<number>(pageSize);
  const [error, setError] = useState<String | null>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageNo = params.get("page");
    if (pageNo) {
      setPage(parseInt(pageNo));
    }
    const pageSize = params.get("size");
    if (pageSize) {
      setSize(parseInt(pageSize));
    }
  }, [location.search]);

  const changePage = () => {
    if (inputPage !== null) {
      onChangePage(inputPage);
    }
  };

  const onInputChange = (value: string) => {
    var parsed = parseInt(value) - 1;
    if (parsed > count - 1) {
      setError(`Klaida! Maksimalus puslapio numeris yra: ${count}`);
    } else if (parsed < 0) {
      setError(`Klaida! Minimalus puslapio numeris yra: 1`);
    } else {
      setError(null);
      setInputPage(parsed);
    }
  };

  const onChangePage = (page: number) => {
    const params = new URLSearchParams(location.search);
    params.delete("page");
    params.append("page", page.toString());
    history.replace({ search: params.toString() });
  };

  const onChangeSize = (size: string) => {
    const params = new URLSearchParams(location.search);
    params.delete("size");
    params.delete("page");
    params.append("page", "0");
    params.append("size", size);
    history.replace({ search: params.toString() });
  };

  return (
    <div className={classes.root}>
      {error && <FormHelperText error>{error}</FormHelperText>}
      <InputBase
        className={classes.input}
        type="number"
        placeholder="Eiti į puslapį"
        inputProps={{
          "aria-label": "eiti į puslapį",
          min: 1,
          max: count,
        }}
        onChange={(e) => onInputChange(e.target.value)}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="default"
        className={classes.iconButton}
        aria-label="directions"
        onClick={changePage}
        disabled={error ? true : false}
      >
        <DirectionsIcon />
      </IconButton>
      <TablePagination
        labelRowsPerPage={"Puslapio dydis"}
        size="small"
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={size}
        count={count}
        page={page}
        onChangePage={(event, page) => onChangePage(page)}
        onChangeRowsPerPage={(e) => onChangeSize(e.target.value)}
      />
    </div>
  );
};
