import {
  Accordion,
  AccordionDetails,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ClientModel from "../../core/api/clients/ClientModel";
import Pagination from "../../core/api/PageRequest";
import { useClientFilter } from "../../hooks/ClientFilterProvider";
import { GoToPagination } from "../GoToPagination";
import ClientTable from "./ClientTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .05)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
}));

const parsePagination = (search: string): Pagination => {
  const params = new URLSearchParams(search);
  const pageNo = parseInt(params.get("page") || "0");
  return { page: pageNo, size: 5 };
};

export const ClientTableAccordion: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const clientFilterProvider = useClientFilter();

  const [clients, setClients] = useState<ClientModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pagination, setPagination] = useState<Pagination>(
    parsePagination(location.search)
  );

  useEffect(() => {
    setPagination(parsePagination(location.search));
  }, [location.search]);

  const fetchClients = useCallback(async () => {
    setIsLoading(true);

    const clientPage = await ClientModel.emailClientList(
      {
        ...clientFilterProvider.filter,
      },
      pagination
    );
    setTotalCount(clientPage.totalElements);
    setClients(clientPage.content);
    setIsLoading(false);
  }, [pagination, clientFilterProvider.filter]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <Typography className={classes.heading}>
          Pasirinkti klientai: {totalCount}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.root}>
        <div className={classes.root}>
          <ClientTable
            toolbar={false}
            clients={clients}
            isLoading={isLoading}
            pagination={
              <GoToPagination
                count={totalCount}
                rowsPerPageOptions={[5]}
                pageSize={pagination.size}
              />
            }
            pageSize={pagination.size}
            refresh={fetchClients}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
