import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ClassifierModel from "../../core/api/ClassifierModel";

const ManagersSelectInput: React.FC<SelectProps> = (props) => {
  const [managers, setManagers] = useState([] as ClassifierModel[]);
  useEffect(() => {
    ClassifierModel.usersClassifier().then((s: any) => {
      setManagers(s);
    });
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel
        id="user-responsible-label"
        className={props.className}
        variant={props.variant}
      >
        {props.label}
      </InputLabel>
      <Select
        fullWidth
        labelWidth={0}
        labelId="user-responsible-label"
        {...props}
      >
        <MenuItem value="">Nepasirinktas</MenuItem>
        {managers.map((manager: { id: any; name: any }) => (
          <MenuItem key={manager.id} value={manager.id}>
            {manager.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ManagersSelectInput;
