import * as Yup from "yup";
import EmailModel from "../email/EmailModel";

class InvoiceEmailModel extends EmailModel {
  recipients!: string[];

  static defaultValue: InvoiceEmailModel = {
    htmlText: "",
    subject: "",
    attachments: [],
    recipients: [],
    important: false,
  };

  static schema = Yup.object().shape({
    htmlText: Yup.string().required("Turinys yra privalomas"),
    subject: Yup.string().required("Laiško tema yra privaloma"),
    recipients: Yup.array<string>().min(
      1,
      "Bent vienas el. pašto adresas yra reikalingas"
    ),
  });
}
export default InvoiceEmailModel;
