import React from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import ReminderModel from "../../core/api/reminder/ReminderModel";
import { useNotifications } from "../../hooks/NotificationProvider";
import EntityId from "../../core/api/EntityId";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "450px",
      display: "block",
      margin: "0 auto",
    },
    textField: {
      "& > *": {
        width: "100%",
      },
    },
    submitButton: {
      marginTop: "24px",
    },
    title: { textAlign: "center" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface Props {
  value: ReminderModel;
  save(model: ReminderModel): Promise<EntityId>;
  close(): void;
}

const ReminderModalForm: React.FC<Props> = ({ save, close, value }) => {
  const classes = useStyles();
  const notifications = useNotifications();

  return (
    <Dialog open={true} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Priminimų forma</DialogTitle>
      <Formik
        initialValues={value}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          save(values)
            .then(close)
            .then(() =>
              notifications.notify({
                level: "success",
                message: "Išsaugota sėkmingai",
              })
            )
            .catch((error) => {
              notifications.notify({
                level: "error",
                message: error.data,
              });
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={ReminderModel.schema}
      >
        {(props: FormikProps<ReminderModel>) => {
          const {
            values,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props;
          return (
            <Form>
              <DialogActions>
                <Button
                  type="button"
                  className="outline"
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Atšaukti
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  Išsaugoti
                </Button>
              </DialogActions>
              <DialogContent>
                <Grid
                  container
                  justify="space-around"
                  direction="row"
                  spacing={2}
                >
                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className={classes.textField}
                  >
                    <TextField
                      id="date"
                      type="datetime-local"
                      name="date"
                      label="Data"
                      onChange={handleChange}
                      helperText={errors.date}
                      fullWidth
                      value={values.date}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className={classes.textField}
                  >
                    <TextField
                      name="comment"
                      id="comment"
                      label="Komentaras"
                      value={values.comment}
                      type="text"
                      multiline
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ReminderModalForm;
