import React from "react";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useNotifications } from "../../hooks/NotificationProvider";
import ClientStateSelectInput from "../classifier/ClientStateSelectInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "450px",
      display: "block",
      margin: "0 auto",
    },
    textField: {
      "& > *": {
        width: "100%",
      },
    },
    margin: {
      margin: theme.spacing(1),
    },
    submitButton: {
      marginTop: "24px",
    },
    title: { textAlign: "center" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface Props {
  save(state: string): Promise<number>;
  close(): void;
}

interface StateForm {
  clientState: string;
}

const ClientStateModalForm: React.FC<Props> = ({ save, close }) => {
  const classes = useStyles();
  const notifications = useNotifications();

  return (
    <Dialog
      open={true}
      fullWidth
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Kliento statuso keitimo forma
      </DialogTitle>
      <Formik
        initialValues={{
          clientState: "",
        }}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          save(values.clientState)
            .then((count) => {
              close();
              return count;
            })
            .then((count) =>
              notifications.notify({
                level: "success",
                message: `Sėkmingai atnaujinti ${count} klientai`,
              })
            )
            .catch((error) => {
              notifications.notify({
                level: "error",
                message: error.data,
              });
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={Yup.object().shape({
          clientState: Yup.string().required("Statusas yra privalomas"),
        })}
      >
        {(props: FormikProps<StateForm>) => {
          const {
            values,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            touched,
          } = props;
          return (
            <Form>
              <DialogContent>
                <ClientStateSelectInput
                  className={classes.margin}
                  name="clientState"
                  labelId="user-responsible-label"
                  id="user-type-select"
                  value={values.clientState}
                  label="Kliento statusas"
                  error={Boolean(errors.clientState)}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  variant="outlined"
                ></ClientStateSelectInput>
              </DialogContent>
              <DialogActions>
                <Button
                  type="button"
                  className="outline"
                  onClick={close}
                  disabled={isSubmitting}
                >
                  Atšaukti
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  Išsaugoti
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ClientStateModalForm;
