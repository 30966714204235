import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ClientModel from "../../core/api/clients/ClientModel";
import Pagination from "../../core/api/PageRequest";
import { parseFilter, useClientFilter } from "../../hooks/ClientFilterProvider";
import { GoToPagination } from "../GoToPagination";
import ClientTable from "./ClientTable";
import qs from "qs";
import PersonAddRoundedIcon from "@material-ui/icons/PersonAddRounded";
import EditIcon from "@material-ui/icons/Edit";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import { PersistentSearchHeader } from "../PersistentSearchHeader";
import { IconButton } from "@material-ui/core";
import ClientsBulkUpdateModalForm from "./ClientsBulkUpdateModalForm";
import ReminderModalForm from "../reminder/ReminderModalForm";
import ReminderModel from "../../core/api/reminder/ReminderModel";
import { parsePagination } from "../../core/PaginationUtils";

export const ClientListTable: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const clientFilterProvider = useClientFilter();

  const [modifyClients, setModifyClients] = useState(false);
  const [createReminders, setCreateReminders] = useState(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pagination, setPagination] = useState<Pagination>(
    parsePagination(location.search)
  );

  // useEffect(() => {
  //   setPagination(parsePagination(location.search));
  // }, [location.search]);

  const fetchClients = useCallback(async () => {
    setIsLoading(true);
    const pagination = parsePagination(location.search);
    setPagination(parsePagination(location.search));
    const clientPage = await ClientModel.clientList(
      parseFilter(location.search),
      pagination
    );
    setTotalCount(clientPage.totalElements);
    setClients(clientPage.content);
    setIsLoading(false);
  }, [location.search]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return (
    <div>
      <ClientTable
        customHeader={
          <PersistentSearchHeader
            title="Klientai"
            actions={
              <span>
                <IconButton
                  color="primary"
                  aria-label="Kurti klientą"
                  onClick={() => {
                    history.push({
                      pathname: "/client/",
                      search: qs.stringify({
                        myClients: clientFilterProvider.filter.myClients,
                      }),
                    });
                  }}
                >
                  <PersonAddRoundedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="Redaguoti klientų sąrašą"
                  onClick={() => setModifyClients(true)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="Kurti priminimus"
                  onClick={() => setCreateReminders(true)}
                >
                  <AddAlertIcon />
                </IconButton>
              </span>
            }
          />
        }
        toolbar={true}
        clients={clients}
        isLoading={isLoading}
        pagination={
          <GoToPagination
            count={totalCount}
            rowsPerPageOptions={[10]}
            pageSize={pagination.size}
          />
        }
        refresh={fetchClients}
        pageSize={pagination.size}
      />
      {modifyClients && (
        <ClientsBulkUpdateModalForm
          filter={clientFilterProvider.filter}
          refresh={fetchClients}
          close={() => setModifyClients(false)}
        />
      )}
      {createReminders && (
        <ReminderModalForm
          value={ReminderModel.defaultValue}
          save={(values) =>
            ReminderModel.createRemindersList(
              values,
              clientFilterProvider.filter
            )
          }
          close={() => setCreateReminders(false)}
        />
      )}
    </div>
  );
};
