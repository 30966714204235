import React from "react";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  TextField,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import ClientFilter, {
  defaultFilter,
} from "../../core/api/clients/ClientFilter";
import ClientStateSelectInput from "../classifier/ClientStateSelectInput";
import { TazDatePicker } from "../dates/TazDatePicker";
import RemoveIcon from "@material-ui/icons/Remove";
import qs from "qs";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";

interface Props {
  filter: ClientFilter;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
  })
);

const ClientFilterForm: React.FC<Props> = ({ filter }) => {
  const classes = useStyles();
  const history = useHistory();

  const onReset = () => {
    history.replace({
      search: qs.stringify(defaultFilter, {
        serializeDate: (date: Date) => {
          return format(date, "yyyy-MM-dd");
        },
      }),
    });
  };

  return (
    <Formik
      initialValues={filter}
      enableReinitialize={true}
      onSubmit={(values) => {
        history.replace({
          search: qs.stringify(values, {
            serializeDate: (date: Date) => {
              return format(date, "yyyy-MM-dd");
            },
          }),
        });
      }}
    >
      {(props: FormikProps<ClientFilter>) => {
        const { values, handleChange } = props;
        return (
          <Form>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              spacing={1}
              className={classes.margin}
            >
              <Grid item xs>
                <TextField
                  fullWidth
                  id="filter"
                  type="text"
                  name="filter"
                  label="Filtras"
                  onChange={handleChange}
                  value={values.filter}
                />
              </Grid>
              <Grid item xs>
                <ClientStateSelectInput
                  name="state"
                  fullWidth
                  id="user-type-select"
                  value={values.state}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs>
                <TazDatePicker
                  id="validFrom"
                  name="validFrom"
                  label="Prenumeratos pabaiga nuo"
                  value={values.validFrom}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TazDatePicker
                  id="validTo"
                  name="validTo"
                  label="Prenumeratos pabaiga iki"
                  value={values.validTo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id="idFrom"
                  type="number"
                  name="idFrom"
                  label="ID nuo"
                  fullWidth
                  onChange={handleChange}
                  value={values.idFrom}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="idTo"
                  type="number"
                  name="idTo"
                  label="ID iki"
                  fullWidth
                  onChange={handleChange}
                  value={values.idTo}
                />
              </Grid>
              <Grid item>
                <InputLabel id="emailsLabel-label">
                  Klientų ID sąrašas:
                </InputLabel>
                <FieldArray
                  name="ids"
                  render={(arrayHelpers) => (
                    <div>
                      {values.ids &&
                        values.ids.map((id, index) => (
                          <div key={index}>
                            <TextField
                              id={`ids[${index}]`}
                              type="text"
                              name={`ids[${index}]`}
                              onChange={handleChange}
                              value={id}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    color="secondary"
                                    aria-label="Išimti adresą"
                                    component="span"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                ),
                              }}
                            />
                          </div>
                        ))}
                      <IconButton
                        color="primary"
                        aria-label="Pridėti adresą"
                        component="span"
                        onClick={() => arrayHelpers.push("")}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Filtruoti
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={onReset}
                >
                  Išvalyti
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ClientFilterForm;
