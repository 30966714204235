import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import InvoiceFilter from "../../core/api/invoice/InvoiceFilterModel";
import { userInvoiceStatistics } from "../../core/api/statistics/StatisticsService";
import UserInvoiceStatistics from "../../core/api/statistics/UserInvoiceStatistics";
import { icons } from "../TableIcons";

export interface TableProps {
  filter?: InvoiceFilter;
}

function sum(x: number | undefined, y: number | undefined) {
  if (x && y) {
    return +x + y;
  }
  return x || y;
}

function totalAcumulator(
  acumulator: UserInvoiceStatistics,
  initialValue: UserInvoiceStatistics
): UserInvoiceStatistics {
  return {
    createdByName: "Iš viso",
    pvm: Number(sum(initialValue.pvm, acumulator.pvm)?.toFixed(2)),
    sum: Number(sum(initialValue.sum, acumulator.sum)?.toFixed(2)),
    count: Number(sum(initialValue.count, acumulator.count)?.toFixed(2)),
    discount: Number(
      sum(initialValue.discount, acumulator.discount)?.toFixed(2)
    ),
    price: Number(sum(initialValue.price, acumulator.price)?.toFixed(2)),
  };
}

const InvoiceStatisticsTable: React.FC<TableProps> = (props) => {
  const [data, setData] = useState<UserInvoiceStatistics[]>([]);
  useEffect(() => {
    userInvoiceStatistics(props.filter).then((r) => {
      r.push({});
      r.push(r.reduce(totalAcumulator));
      setData(r);
    });
  }, [props.filter]);

  return (
    <MaterialTable
      icons={icons}
      columns={[
        { title: "", field: "createdByName" },
        { title: "Sąskaitų skaičius", field: "count" },
        { title: "Kaina", field: "price" },
        { title: "Nuolaida", field: "discount" },
        { title: "PVM", field: "pvm" },
        { title: "Viso", field: "sum" },
      ]}
      options={{
        padding: "dense",
        sorting: false,
        search: false,
        paging: false,
        headerStyle: {
          backgroundColor: "#bdbdbd",
        },
        rowStyle: (rowData, index) => {
          if (index % 2) {
            return {};
          }
          return { backgroundColor: "#efefef" };
        },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: "Sąskaitų statistikos nėra",
        },
      }}
      data={data}
      title={`Statistika nuo
       ${props.filter?.dateFrom} 
       iki ${props.filter?.dateTo}`}
    />
  );
};

export default InvoiceStatisticsTable;
