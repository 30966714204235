import { InputProps, TextField } from "@material-ui/core";
import * as React from "react";

interface Props {
  id: string;
  name: string;
  label: string;
  value: string | null;
  onChange(e: React.ChangeEvent<any>): void;
  error?: boolean;
  helperText?: React.ReactNode;
  onBlur?: (event: React.FocusEvent) => void;
  disabled?: boolean;
  className?: string;
  variant?: "outlined" | undefined;
  InputProps?: InputProps;
}

export const TazDatePicker: React.FC<Props> = (props) => {
  const [date, setDate] = React.useState("");
  React.useEffect(() => {
    if (props.value) {
      setDate(props.value as string);
    } else {
      setDate("");
    }
  }, [props.value]);

  return (
    <TextField
      fullWidth
      className={props.className}
      id={props.id}
      type="date"
      name={props.name}
      label={props.label}
      value={date}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      disabled={props.disabled}
      InputProps={props.InputProps}
      InputLabelProps={{
        shrink: true,
      }}
      placeholder=""
    />
  );
};
