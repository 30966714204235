import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import PasswordReset from "./auth/PasswordReset";
import { NotFound } from "./NotFound";
import Login from "./auth/Login";
import { Layout } from "../components/layout/Layout";
import { useAuth } from "../hooks/AuthProvider";
import { CircularProgress } from "@material-ui/core";

export const AppRoutes: React.FC = () => {
  const auth = useAuth();
  return auth.isLoading ? (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Switch>
      <Route path="/remember/:token" component={PasswordReset} />
      <Route path="/not-found" component={NotFound} />
      <Route path="/login" component={Login} />

      {!auth.isLoading && auth.isLoggedIn && <Route component={Layout} />}
      {!auth.isLoading && !auth.isLoggedIn && <Redirect to="/login" />}
    </Switch>
  );
};
