import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { ReactNode } from "react";
import { TableSearch } from "./TableSearch";

interface Props {
    title: string
    actions?: ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            margin: theme.spacing(1),
        },
        item: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    })
);

export const PersistentSearchHeader: React.FC<Props> = ({ title, actions }) => {
    const classes = useStyles();

    return <Grid container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
        className={classes.root}
    >
        <Grid item className={classes.item}>
            <Typography variant="h6">{title}</Typography>
        </Grid>

        <Grid item className={classes.item}>
            <>{actions}</>
            <TableSearch />
        </Grid>


    </Grid>
}