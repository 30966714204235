import { format, parseISO } from "date-fns";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import MonthlyIncome from "../../core/api/statistics/MonthlyIncome";
import { monthlyIncome } from "../../core/api/statistics/StatisticsService";
import { icons } from "../TableIcons";

const IncomeTable: React.FC = (props) => {
  const [data, setData] = useState<MonthlyIncome[]>([]);
  useEffect(() => {
    monthlyIncome().then((r) => setData(r));
  }, []);

  return (
    <MaterialTable
      icons={icons}
      columns={[
        {
          title: "Data",
          field: "date",
          type: "date",
          cellStyle: {
            minWidth: "100px",
          },
          render: (rowData) => {
            return <span>{format(parseISO(rowData.date), "yyyy-MM")}</span>;
          },
        },
        { title: "Kaina", field: "totalPrice" },
        { title: "PVM", field: "totalPvm" },
        { title: "Suma", field: "sum" },
      ]}
      options={{
        padding: "dense",
        sorting: false,
        search: false,
        paging: false,
        maxBodyHeight: "460px",
        headerStyle: {
          backgroundColor: "#bdbdbd",
        },
        rowStyle: (rowData, index) => {
          if (index % 2) {
            return {};
          }
          return { backgroundColor: "#efefef" };
        },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: "Pajamų statistikos nėra",
        },
      }}
      data={data}
      title="Mėnesinės pajamos"
    />
  );
};

export default IncomeTable;
