import * as Yup from "yup";
import ClientFilter from "../clients/ClientFilter";
import httpClient from "../config/HttpClient";

const EMAIL_URL = "/emails";

export interface EmailResults {
  id: String;
  senderName: String;
  sentCount: number;
  failedCount: number;
  invoiceSent: boolean;
  filter: ClientFilter;
}

class EmailModel {
  htmlText!: string;
  subject!: string;
  attachments!: string[];
  important!: boolean;

  static defaultValue: EmailModel = {
    htmlText: "",
    subject: "",
    attachments: [],
    important: false,
  };

  static schema = Yup.object().shape({
    htmlText: Yup.string().required("Turinys yra privalomas"),
    subject: Yup.string().required("Laiško tema yra privaloma"),
  });

  static sendInvoice = async (content: EmailModel, invoiceNo: String) => {
    return await httpClient
      .post(EMAIL_URL + "/invoices/" + invoiceNo + "/send", content)
      .then((resp) => resp.data);
  };
}
export default EmailModel;
