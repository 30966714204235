import { makeStyles, Theme, createStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: "none",
    },
  })
);

interface Props {
  pdfUrl: () => Promise<string>;
  close: () => void;
}
const InvoicePrintComponent: React.FC<Props> = ({ pdfUrl, close }) => {
  const [invoiceUrl, setInvoiceUrl] = React.useState<string | null>(null);
  const classes = useStyles();

  const print = () => {
    const iframe = document.getElementById("printFrame") as HTMLIFrameElement;
    const iframeWindow = iframe.contentWindow;
    if (iframeWindow) {
      iframeWindow.focus();
      iframeWindow.print();
    } else {
      close();
    }
  };
  React.useEffect(() => {
    pdfUrl().then(setInvoiceUrl);
  }, [pdfUrl]);

  return (
    <div className={classes.hidden}>
      {invoiceUrl && (
        <iframe
          id="printFrame"
          title="sąskaita"
          src={invoiceUrl}
          onLoad={print}
          width="700"
          height="500"
        >
          <p>Naršyklė nepalaiko PDF peržiūros!</p>
        </iframe>
      )}
    </div>
  );
};

export default InvoicePrintComponent;
